import { Outlet } from 'react-router-dom';

import {
  FEATURE_FLAGS,
  useIsFeatureEnabled,
} from '~/core/hooks/core/useIsFeatureEnabled';
import Page404 from '~/pages/common/Page404';
import PageLoading from '~/pages/common/PageLoading';

interface FeatureFlaggedRouteWrapperProps {
  featureFlag: FEATURE_FLAGS;
  children?: JSX.Element | React.ReactNode;
}

export const FeatureFlaggedRouteWrapper = ({
  featureFlag,
  children,
}: FeatureFlaggedRouteWrapperProps) => {
  const isEnabled = useIsFeatureEnabled(featureFlag);
  if (children) {
    return isEnabled ? children : <Page404 />;
  }
  // Until isEnabled is a boolean, we want to show a loading state so Page404 doesn't flash
  if (isEnabled === undefined) return <PageLoading />;
  return isEnabled ? <Outlet /> : <Page404 />;
};
