import { ConfigProvider } from '@weavebio/ui-toolkit';

import { THEME } from './theme';

interface WeaveIndThemeProviderProps {
  children: React.ReactNode;
}

const WeaveIndThemeProvider = ({ children }: WeaveIndThemeProviderProps) => {
  return <ConfigProvider theme={THEME}>{children}</ConfigProvider>;
};

export default WeaveIndThemeProvider;
