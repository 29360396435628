import axios from 'axios';

import { DEFAULT_HEADERS } from '~/core/api/const';

const indApi = axios.create({
  baseURL: import.meta.env.VITE_IND_API_URL,
  timeout: +import.meta.env.VITE_API_TIMEOUT,
  headers: DEFAULT_HEADERS,
  responseType: 'json',
  withCredentials: true,
});

indApi.interceptors.response.use(
  (response) => {
    const data = response.data as BaseResponse<unknown>;
    return data.data as any;
  },
  (error) => {
    if (!error.response) {
      const error: ApplicationError = {
        message: 'Network Error',
        extra: {
          path: '',
          statusCode: 500,
          timestamp: new Date().toISOString(),
        },
      };
      return Promise.reject(error);
    }

    const err = error.response.data as ApplicationError;
    if (error.response.status === 401) {
      console.error('Unauthorized access:', err);
    }

    return Promise.reject(err);
  },
);

export { indApi };
