import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type DataRoomState = {
  uploadingFiles: any[];
  setUploadingFiles: (
    uploadingFiles: DataRoomState['uploadingFiles'][],
  ) => void;
};

export const useDataRoomStore = create<DataRoomState>()(
  devtools(
    (set) => ({
      uploadingFiles: [],
      setUploadingFiles: (uploadingFiles) =>
        set(() => ({ uploadingFiles: uploadingFiles })),
    }),
    { name: 'data-room-store' },
  ),
);

export const useUploadingFiles = () =>
  useDataRoomStore((state) => state.uploadingFiles);

export const useDataRoomStoreActions = () =>
  useDataRoomStore((state) => ({
    setUploadingFiles: state.setUploadingFiles,
  }));
