import type { Accept } from 'react-dropzone';

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const NO_TOKEN_ERROR = 'No token found';

export const FILE_UPLOAD_TYPES: Accept = {
  'application/pdf': ['.pdf'],
};

export const MAX_FILE_SIZE = 3221225472; // 3 Gb
