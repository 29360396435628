import to from 'await-to-js';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  HttpStatusCode,
  ResponseType,
} from 'axios';

import logger, { LogAreaType } from '~/core/providers/logger';
import { WeaveApiResponse } from '~/types/WeaveApiResponse';

interface IndHttpAdapterConstructorArguments {
  baseUrl: string;
  timeout?: number;
  headers?: AxiosHeaders;
  responseType?: ResponseType;
}

interface BaseHttpArguments {
  endpoint: string;
  params?: any;
  headerOverride?: any;
  expectDataPayloadAccompanyingErrorCodes?: Array<HttpStatusCode>;
  logCall?: boolean;
}

interface DataHttpArguments extends BaseHttpArguments {
  data: any;
}

export interface IndHttpAdapterInterface {
  delete: <T>(postArgs: BaseHttpArguments) => Promise<WeaveApiResponse<T>>;
  get: <T>(getArgs: BaseHttpArguments) => Promise<WeaveApiResponse<T>>;
  put: <T>(putArgs: DataHttpArguments) => Promise<WeaveApiResponse<T>>;
  post: <T>(postArgs: DataHttpArguments) => Promise<WeaveApiResponse<T>>;
  patch: <T>(postArgs: DataHttpArguments) => Promise<WeaveApiResponse<T>>;
}

export class IndHttpAdapter implements IndHttpAdapterInterface {
  private _baseUrl: string;
  private _timeout: number;
  private _responseType: ResponseType;

  constructor(constructorArguments?: IndHttpAdapterConstructorArguments) {
    const initialBaseUrl =
      constructorArguments?.baseUrl || import.meta.env.VITE_IND_API_URL;
    if (!initialBaseUrl) {
      throw new Error(
        'The initial base url for the HTTP adapter has not been set.  Please check your .env for the appropriate setting!',
      );
    }
    this._baseUrl =
      initialBaseUrl.endsWith('/') ?
        initialBaseUrl.slice(0, -1)
      : initialBaseUrl;
    this._timeout =
      constructorArguments?.timeout || +import.meta.env.VITE_API_TIMEOUT;
    this._responseType = constructorArguments?.responseType || 'json';
  }

  async delete<T>(httpDeleteArguments: BaseHttpArguments) {
    return await this.httpMethod<T>('delete', {
      ...httpDeleteArguments,
      data: null,
    });
  }

  async get<T>(httpGetArguments: BaseHttpArguments) {
    return await this.httpMethod<T>('get', { ...httpGetArguments, data: null });
  }

  async put<T>(httpPutArguments: DataHttpArguments) {
    return await this.httpMethod<T>('put', httpPutArguments);
  }

  async patch<T>(httpPatchArguments: DataHttpArguments) {
    return await this.httpMethod<T>('patch', httpPatchArguments);
  }

  async post<T>(httpPostArguments: DataHttpArguments) {
    return await this.httpMethod<T>('post', httpPostArguments);
  }

  private async httpMethod<T>(
    methodName: 'delete' | 'get' | 'patch' | 'put' | 'post',
    {
      endpoint,
      params,
      headerOverride,
      data,
      logCall,
      expectDataPayloadAccompanyingErrorCodes = [],
    }: DataHttpArguments,
  ) {
    const client = await this.createClient();
    const clientArgs =
      headerOverride ?
        {
          params,
          headers: headerOverride,
        }
      : { params };

    const fullUrl = `${this._baseUrl}/${endpoint}`;

    const [apiErr, response] =
      ['get', 'delete'].includes(methodName) ?
        await to(client[methodName](fullUrl, clientArgs))
      : await to(client[methodName](fullUrl, data, clientArgs));

    // If there are no errors, handle a successful response
    if (!apiErr) {
      return this.handleSuccessfulResponse<T>({
        response,
        fullUrl,
        methodName,
        logCall,
        params,
        data,
      });
    }

    // If there are errors, format data from the error to return
    const apiErrorStatus = (apiErr as AxiosError)?.response?.status;
    const apiError = {
      extra: { apiErr },
      message: apiErr.message,
      status: apiErrorStatus,
    };

    const apiErrorData = (apiErr as AxiosError)?.response?.data;

    // If the error is expected, handle the expected error
    if (
      this.receivedAnExpectedHttpError(
        expectDataPayloadAccompanyingErrorCodes,
        apiErrorStatus,
      )
    ) {
      return this.handleExpectedErrorResponse<T>({
        apiErrorData: apiError,
        fullUrl,
        methodName,
        logCall,
        params,
        data: apiErrorData,
      });
    }

    // If the error is not expected, handle the error
    return this.handleErrorResponse<T>({ apiErrorData, methodName, fullUrl });
  }

  private async handleSuccessfulResponse<T>({
    response,
    fullUrl,
    methodName,
    logCall,
    params,
    data,
  }: {
    response: any;
    fullUrl: string;
    methodName: 'get' | 'put' | 'post' | 'patch' | 'delete';
    logCall?: boolean;
    params?: any;
    data?: any;
  }) {
    const apiResponse: WeaveApiResponse<T> = {
      data: response?.data,
      error: undefined,
    };

    if (logCall) {
      logger.logGroup(
        `indHttpAdapter: ${methodName.toUpperCase()} [success]`,
        LogAreaType.Http,
        () => {
          let requestInfo: any = {
            fullUrl,
            apiResponse,
          };
          if (methodName === 'get') {
            requestInfo = { ...requestInfo, params };
          }
          if (['put', 'post', 'patch'].includes(methodName)) {
            requestInfo = { ...requestInfo, data };
          }
          logger.log('request info:', requestInfo);
        },
      );
    }
    return apiResponse;
  }

  private async handleExpectedErrorResponse<T>({
    apiErrorData,
    fullUrl,
    methodName,
    logCall,
    params,
    data,
  }: {
    apiErrorData: any;
    fullUrl: string;
    methodName: 'get' | 'put' | 'post' | 'patch' | 'delete';
    logCall?: boolean;
    params?: any;
    data?: any;
  }) {
    const apiResponse: WeaveApiResponse<T> = {
      data: data as T,
      error: apiErrorData as any,
    };

    if (logCall) {
      logger.logGroup(
        `indHttpAdapter: ${methodName.toUpperCase()} received expected error, payload reported.`,
        LogAreaType.Http,
        () => {
          let requestInfo: any = {
            fullUrl,
            apiResponse,
          };
          if (methodName === 'get') {
            requestInfo = { ...requestInfo, params };
          }
          if (['put', 'post', 'patch'].includes(methodName)) {
            requestInfo = { ...requestInfo, data };
          }
          logger.log('request info:', requestInfo);
        },
      );
    }
    return apiResponse;
  }

  private receivedAnExpectedHttpError(
    expectedErrorCodes: Array<HttpStatusCode>,
    apiErrorStatus?: number,
  ) {
    return (
      expectedErrorCodes.length > 0 &&
      apiErrorStatus &&
      expectedErrorCodes.includes(apiErrorStatus)
    );
  }

  private handleErrorResponse<T>({
    apiErrorData,
    methodName,
    fullUrl,
  }: {
    apiErrorData: any;
    methodName: 'get' | 'put' | 'post' | 'patch' | 'delete';
    fullUrl: string;
  }) {
    let apiErrorResponse: WeaveApiResponse<T>;
    if (!apiErrorData) {
      // No error
      apiErrorResponse = {
        data: undefined,
        error: {
          extra: 'Empty error',
          message: 'Empty error',
          status: 555,
        },
      };
    } else {
      // There's an error, may or may not be instance of ApplicationError
      apiErrorResponse = {
        data: undefined,
        error: {
          extra: (apiErrorData as any)?.extra || 'No extra data provided',
          message: (apiErrorData as any)?.message || 'No message provided',
          status: (apiErrorData as any)?.status || 556,
        },
      };
    }

    logger.logError(
      `indHttpAdapter: ${methodName.toUpperCase()} [error]: ${fullUrl}`,
      {
        fullUrl,
        apiErrorResponse,
      },
    );
    return apiErrorResponse;
  }

  private createClient: () => AxiosInstance = () => {
    return axios.create({
      baseURL: this._baseUrl,
      timeout: this._timeout,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      responseType: this._responseType,
      withCredentials: true,
    });
  };
}
