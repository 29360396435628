export const AUTO_DETECT = 'autodetect';
export const IND_DOCUMENT_ELEMENT_ID = 'ind-app-document';

export const UNRECOGNIZED_ERROR_MESSAGE =
  "Something's not right. We've taken note of this mistake and are working on fixing it.";

export const literatureExamples = [
  'Novel drug targets published in the last year',
  'Recent clinical trial publications',
  'Overview of the disease mechanism',
  'Unsolved challenges',
];

export const newsExamples = [
  'Clinical trial outcomes',
  'Recent biotech company failures',
  'Recent announcements of available company funding',
  'Drug asset divestments',
];

export const literatureSources = [
  { text: 'PubMed abstracts', url: 'https://pubmed.ncbi.nlm.nih.gov/' },
];
export const newsSources = [
  { text: 'Stat News', url: 'https://statnews.com' },
  { text: 'BioSpace', url: 'https://biospace.com' },
  { text: 'Biotechblog', url: 'https://biotechblog.com' },
  { text: 'BioPharma Dive', url: 'https://biopharmadive.com' },
  { text: 'Endpoints News', url: 'https://endpts.com/' },
  { text: 'Forbes Healthcare', url: 'https://www.forbes.com/healthcare' },
  { text: 'MarketWatch', url: 'https://www.marketwatch.com/' },
  { text: 'Business Wire', url: 'https://www.businesswire.com/' },
  { text: 'PR Newswire', url: 'https://www.prnewswire.com/' },
  { text: 'Washington Post', url: 'https://www.washingtonpost.com/' },
  { text: 'NY Times', url: 'www.nytimes.com' },
  {
    text: 'Reuters',
    url: 'https://www.reuters.com/business/healthcare-pharmaceuticals',
  },
];

export const cardSources = [...newsSources, ...literatureSources];
export const cardConfigExamples = [...newsExamples, ...literatureExamples];

export const ADD = 'Add';
export const CANCEL = 'Cancel';

export const mockSectionId = '1';
export const mockNotebookId = '1';
export const mockCardId = '1';
export const mockCardType = 'versatile';
export const mockEmail = 'test@email.com';
export const mockScopeInput = 'mock-scope-input';

export const SECONDS = 1000;
