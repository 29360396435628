import { TreeUtils } from '~/core/lib/treeUtils';
import { FriendlyNameMapper } from '~/core/mappers/friendlyNameMapper';

import {
  BackEndDocumentApiResponse,
  BackEndDocumentBlockApiResponse,
  BackEndSectionApiResponse,
  DocumentLock,
  DocumentSection,
  TopLevelDocumentBlock,
} from '../../domain/types';
import { DocumentLockApiResponse } from '../../services/DocumentEditorService/responses';

const mapDocumentLockApiResponseToDocumentLock = (
  apiResponse: DocumentLockApiResponse,
): DocumentLock => {
  return {
    firstName: apiResponse.firstName,
    lastName: apiResponse.lastName,
    friendlyName:
      FriendlyNameMapper.map.profileResponseData.to.friendlyName(apiResponse),
    lockedBy: apiResponse.lockedBy,
    email: apiResponse.email,
    expires: new Date(apiResponse.expires),
    docNumber: apiResponse.docNumber,
  };
};

const createBlockTreeFromContent: (
  sectionNode: any,
  rawBlockContentArray: Array<BackEndDocumentBlockApiResponse>,
) => Array<TopLevelDocumentBlock> = (
  _sectionNode: any,
  rawBlockContentArray: Array<BackEndDocumentBlockApiResponse>,
) => {
  // Convert each block api response to an intermediate response.
  const intermediateBlockApiResponses: Array<any> = rawBlockContentArray.map(
    (rawBlock) => {
      return {
        id: rawBlock.id,
        ind_id: rawBlock.ind,
        block_type: rawBlock.block_type,
        source: rawBlock.source,
        source_properties: rawBlock.source_properties,
        parent: rawBlock.parent,
        position: rawBlock.position,
        created_at: rawBlock.created_at,
        updated_at: rawBlock.updated_at,
        properties: rawBlock.properties,
        refinement_preview:
          rawBlock.block_type === 'text' ?
            rawBlock.refinement_preview
          : undefined,
      };
    },
  );
  const blockTree = TreeUtils.createFromArray({
    sourceArray: intermediateBlockApiResponses,
  });

  return blockTree;
};

const createContentTreeFromSectionResponse: (
  sectionResponse: BackEndSectionApiResponse,
) => Array<TopLevelDocumentBlock> = (
  sectionResponse: BackEndSectionApiResponse,
) => {
  const rawBlockContentArray = sectionResponse.content;
  const intermediateBlockApiResponses: Array<any> = rawBlockContentArray.map(
    (rawBlock) => {
      return {
        id: rawBlock.id,
        ind_id: rawBlock.ind,
        block_type: rawBlock.block_type,
        source: rawBlock.source,
        source_properties: rawBlock.source_properties,
        parent: rawBlock.parent,
        position: rawBlock.position,
        created_at: rawBlock.created_at,
        updated_at: rawBlock.updated_at,
        properties: rawBlock.properties,
        refinement_preview:
          rawBlock.block_type === 'text' ?
            rawBlock.refinement_preview
          : undefined,
      };
    },
  );
  const theRawArray = [sectionResponse, ...intermediateBlockApiResponses];
  const theContentTree = TreeUtils.createFromArray({
    sourceArray: theRawArray,
  });

  // If there's no content tree, return an empty array
  if (!theContentTree || theContentTree.length === 0) {
    return [];
  }
  // Otherwise, the first node's children is what we want to return here.
  return theContentTree[0].children;
};

const createContentTreeFromTerminalDocumentResponse: (
  documentResponse: BackEndDocumentApiResponse,
) => Array<TopLevelDocumentBlock> = (
  documentResponse: BackEndDocumentApiResponse,
) => {
  // In the case of a terminal document, there are no sections present and the content is in an array directly attached
  // to the document, instead of content being in arrays attached to sections.  As we rely on all documents having at least one section,
  // we manipulate the response a bit further than usual to represent this *as* a section.  In a terminal document, the document and the
  // "section" are one and the same.

  // First, we map the block responses to their usual format, accomodating for a difference in the payload types re: ind_id (see below)

  const rawBlockContentArray = documentResponse.content;
  const intermediateBlockApiResponses: Array<any> = rawBlockContentArray.map(
    (rawBlock) => {
      return {
        id: rawBlock.id,
        ind_id: rawBlock.ind_id,
        block_type: rawBlock.block_type,
        source: rawBlock.source,
        source_properties: rawBlock.source_properties,
        parent: rawBlock.parent,
        position: rawBlock.position,
        created_at: rawBlock.created_at,
        updated_at: rawBlock.updated_at,
        properties: rawBlock.properties,
        refinement_preview:
          rawBlock.block_type === 'text' ?
            rawBlock.refinement_preview
          : undefined,
      };
    },
  );

  // We then make a "fake" document section that we're using simply to give the child blocks a parent anchor to properly attach to.
  // We're not going to utilize this beyond this mapping.
  const documentNodeAsBackEndSection: BackEndSectionApiResponse = {
    id: documentResponse.id,
    section_id: documentResponse.document_id,
    block_id: documentResponse.block_id,
    description: documentResponse.description,
    number: documentResponse.number,
    ind_id: documentResponse.ind_id,
    title: documentResponse.title,
    level: documentResponse.level,
    type: documentResponse.type,
    content: documentResponse.content,
    status: documentResponse.status,
    processing_status: documentResponse.processing_status,
    dependency_status: documentResponse.dependency_status,
    status_detail: null,
    failed: documentResponse.failed,
    failed_reason: documentResponse.failed_reason,
    subsections: [],
  };

  const theRawArray = [
    documentNodeAsBackEndSection,
    ...intermediateBlockApiResponses,
  ];
  const theContentTree = TreeUtils.createFromArray({
    sourceArray: theRawArray,
  });

  // If there's no content tree, return an empty array
  if (!theContentTree || theContentTree.length === 0) {
    return [];
  }

  // Otherwise, the first node's children is what we want to return here.
  return theContentTree[0].children;
};

export const flattenSection: (
  apiSectionResponse: BackEndSectionApiResponse,
) => Array<any> = (apiSectionResponse: BackEndSectionApiResponse) => {
  return TreeUtils.flattenTree({
    sourceTree: apiSectionResponse,
    options: {
      childPropName: 'subsections',
    },
  });
};

export const mapBackendSectionResponseToDocumentSectionContentTree: (
  apiSectionResponse: BackEndSectionApiResponse,
) => DocumentSection = (apiSectionResponse: BackEndSectionApiResponse) => {
  const sectionContentTree: DocumentSection = {
    id: apiSectionResponse.id,
    ind_id: apiSectionResponse.ind_id,
    is_document: false,
    level: apiSectionResponse.level,
    type: apiSectionResponse.type,
    description: apiSectionResponse.description,
    status: apiSectionResponse.status,
    processing_status: apiSectionResponse.processing_status,
    dependency_status: apiSectionResponse.dependency_status,
    // Ignore status detail for now
    title: apiSectionResponse.title,
    failed: apiSectionResponse.failed,
    failed_reason: apiSectionResponse.failed_reason,
    // There's no owner given in the apiSectionResponse
    owner: null,
    // There's no review array given in the apiSectionResponse either, looks to be specific to the document node?
    reviews: [],
    section_number: apiSectionResponse.number,
    children: [],
  };

  sectionContentTree.children =
    createContentTreeFromSectionResponse(apiSectionResponse);

  return sectionContentTree;
};

const mapBackendDocumentResponseToFrontEndDocumentFormat = (
  apiResponse: BackEndDocumentApiResponse,
): Array<DocumentSection> => {
  // The first element of this response is actually the *document* represented as a section.

  const documentNode: DocumentSection = {
    children: [],
    description: apiResponse.description,
    failed: apiResponse.failed,
    failed_reason: apiResponse.failed_reason,
    id: apiResponse.id,
    ind_id: apiResponse.ind_id,

    // @ts-expect-error This is the one exception to the is_document rule, made because of the limitations of the
    // section based format.
    is_document: true,
    level: apiResponse.level,
    type: apiResponse.type,
    owner: apiResponse.owner,
    reviews: apiResponse.reviews,
    section_number: apiResponse.number,
    status: apiResponse.status,
    processing_status: apiResponse.processing_status,
    dependency_status: apiResponse.dependency_status,
    title: apiResponse.title,
  };

  documentNode.children = createBlockTreeFromContent(
    documentNode,
    apiResponse.content,
  );

  const flattenedSectionCollections = apiResponse.sections.map(
    (sectionResponse) => flattenSection(sectionResponse),
  );

  const fullyFlattenedSections = (
    [] as Array<BackEndSectionApiResponse>
  ).concat(...flattenedSectionCollections);

  // After the sections have been flattened, run through all of the sections and change their block content
  // into tree format.
  const reconfiguredSections = fullyFlattenedSections.map((section) =>
    mapBackendSectionResponseToDocumentSectionContentTree(section),
  );

  // Return the newly constructed array of sections.
  return [documentNode, ...reconfiguredSections];
};

const mapBackendDocumentResponseToFrontEndTerminalDocumentFormat = (
  apiResponse: BackEndDocumentApiResponse,
): Array<DocumentSection> => {
  const theContentTree =
    createContentTreeFromTerminalDocumentResponse(apiResponse);

  const documentNode: DocumentSection = {
    // In a terminal document, the document and the "section" are one and the same.
    // In this case we directly insert the child content tree into the doc section we're creating.  This
    // is so the save functionality can "re-flatten" the document tree appropriately.
    children: theContentTree,
    description: apiResponse.description,
    failed: apiResponse.failed,
    failed_reason: apiResponse.failed_reason,
    id: apiResponse.id,
    ind_id: apiResponse.ind_id,

    // @ts-expect-error This is the one exception to the is_document rule, made because of the limitations of the
    // section based format.
    is_document: true,
    level: apiResponse.level,
    type: apiResponse.type,
    owner: apiResponse.owner,
    reviews: apiResponse.reviews,
    section_number: apiResponse.number,
    status: apiResponse.status,
    processing_status: apiResponse.processing_status,
    dependency_status: apiResponse.dependency_status,
    title: apiResponse.title,
  };

  return [documentNode];
};

export const DocumentEditorServiceMapper = {
  map: {
    documentLockApiResponse: {
      to: {
        documentLock: mapDocumentLockApiResponseToDocumentLock,
      },
    },
    backendDocumentResponse: {
      to: {
        frontendDocumentFormat:
          mapBackendDocumentResponseToFrontEndDocumentFormat,
        frontendTerminalDocumentFormat:
          mapBackendDocumentResponseToFrontEndTerminalDocumentFormat,
      },
    },
  },
};
