import { App as AntApp, ThemeProvider } from '@weavebio/ui-toolkit';

type Props = {
  children: React.ReactNode;
};

export const WeaveTheme = (props: Props) => {
  return (
    <AntApp style={{ height: '100%' }}>
      <ThemeProvider>{props.children} </ThemeProvider>
    </AntApp>
  );
};
