import { createContext, Key } from 'react';

import { IndTreeFilters } from '~/pages/private/IndManagerPage/types';

export const initialFiltersState: IndTreeFilters = {
  name: '',
  status: undefined,
};

const IndManagerContext = createContext({
  expandedKeys: [] as Key[],
  setExpandedKeys: (keys: Key[]) => {
    keys;
  },
  filters: initialFiltersState,
  setFilters: (filters: IndTreeFilters) => {
    filters;
  },
  resetFilters: () => {},
});

export default IndManagerContext;
