import { AntButton, ButtonProps } from '@weavebio/ui-toolkit';
import { sentenceCase } from 'change-case';

type Props = ButtonProps & {
  children?: React.ReactNode;
  buttonColor?: string;
  bypassCapitalization?: boolean;
};

const indRegex = /\bind\b/gi;

/**
 * @deprecated - please use Weave2024Button in the event you're rendering with a button component.  We'll eventually
 * retire this version and rename the original to WeaveButton to replace it; in the meantime we've left this here to
 * make integration a bit easier.
 */
export const WeaveButton = ({
  buttonColor,
  bypassCapitalization = false,
  ...rest
}: Props) => {
  if (typeof rest.children === 'string' && !bypassCapitalization) {
    return (
      <AntButton {...rest}>
        {sentenceCase(rest.children).replace(indRegex, 'IND')}
      </AntButton>
    );
  }

  return <AntButton {...rest}>{rest.children}</AntButton>;
};
