import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { TreeUtils } from '~/core/lib/treeUtils';

import { DocumentReferenceFileTableSummary } from './types';

interface GetTableListArguments {
  indId: string;
  sectionNumber: string;
}

interface GetTableDetailArguments {
  tableBlockId: string;
  indId: string;
}

const tableEndpoints = {
  associatedTables: (indId: string, sectionNumber: string) =>
    `ind-applications/tables/list/${indId}/${sectionNumber}`,
  tableDetail: (tableBlockId: string) =>
    `ind-applications/block/detail/${tableBlockId}`,
};

const indApiAdapter = new IndHttpAdapter();

const getAssociatedTables = async ({
  indId,
  sectionNumber,
}: GetTableListArguments) => {
  const response = await indApiAdapter.get<DocumentReferenceFileTableSummary>({
    endpoint: tableEndpoints.associatedTables(indId, sectionNumber),
  });
  if (response.error) {
    const emptyDocSummary: DocumentReferenceFileTableSummary = {
      documents: [],
      sectionNumber: sectionNumber,
    };
    return emptyDocSummary;
  } else {
    return response.data as DocumentReferenceFileTableSummary;
  }
};

const getTableDetail = async ({
  indId,
  tableBlockId,
}: GetTableDetailArguments) => {
  const response = await indApiAdapter.get<any>({
    endpoint: tableEndpoints.tableDetail(tableBlockId),
  });
  if (response.error) {
    return undefined;
  } else {
    // Add the indID to this content, since we're adding this block to the document
    const revisedParent = {
      ...response.data.content[0],
      parent: '',
      ind_id: indId,
    };
    const revisedContent = [revisedParent].concat(
      response.data.content.slice(1),
    );

    const revisedBlock = TreeUtils.createFromArray({
      sourceArray: revisedContent,
    })[0];

    return revisedBlock;
  }
};

export { getAssociatedTables, getTableDetail };
