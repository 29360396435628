import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '~/core/hooks/const';
import { getAllInds } from '~/core/services/IndService';

/**
 * @deprecated - The pattern of individual hooks per query/mutation predates the Weave FE team.
 * THis approach has been deprecated in favor of "feature"-based hooks (e.g. useRefinement, useOrganization) that
 * combine related queries and mutations as needed.  Please avoid this usage in favor of using the query directly
 * in a feature hook.  This hook will be retired in the future.
 */
export const useGetIndApplications = () => {
  return useQuery({
    queryKey: QueryKeys.GET_IND_APPLICATIONS(),
    queryFn: () => getAllInds(),
  });
};
