import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { ReviewerData } from '~/features/review-mode/components/AssignReviewers';

import { QcInfoResponse, SentenceFlag } from '../types/qc-types';
const indApiAdapter = new IndHttpAdapter();

const getMoveToQcEndpoint = ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => `ind/${indId}/document/${documentNumber}/qc`;

// It just so happens to be the same as the move to qc endpoint, but
// separating it just in case we need to change it later
const getQcInfoEndpoint = ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => `ind/${indId}/document/${documentNumber}/qc`;

const getQcFlagsForBlockEndpoint = ({
  indId,
  blockId,
}: {
  indId: string;
  blockId: string;
}) => `ind/${indId}/block/${blockId}/qcflags`;

type SubmitForQcParams = {
  indId: string;
  documentNumber: string;
  reviewers: ReviewerData[];
};

const submitForQc = async ({
  indId,
  documentNumber,
  reviewers,
}: SubmitForQcParams) => {
  const endpoint = getMoveToQcEndpoint({ indId, documentNumber });
  return await indApiAdapter.post<IndSectionOutline>({
    endpoint,
    data: {
      status: 'InQC',
      reviewers: reviewers.map((reviewer) => reviewer.uid),
    },
  });
};

const finalizeQc = async ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => {
  const endpoint = getMoveToQcEndpoint({ indId, documentNumber });
  return await indApiAdapter.post<IndSectionOutline>({
    endpoint,
    data: {
      status: 'Completed',
    },
  });
};

const backToDraft = async ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => {
  const endpoint = getMoveToQcEndpoint({ indId, documentNumber });
  return await indApiAdapter.post<IndSectionOutline>({
    endpoint,
    data: {
      status: 'Drafted',
    },
  });
};

const getQcInfo = async ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => {
  const endpoint = getQcInfoEndpoint({ indId, documentNumber });
  return await indApiAdapter.get<QcInfoResponse>({ endpoint });
};

const updateQcFlagsForBlock = async ({
  indId,
  blockId,
  flags,
}: {
  indId: string;
  blockId: string;
  flags: Map<number, SentenceFlag>;
}) => {
  const endpoint = getQcFlagsForBlockEndpoint({ indId, blockId });
  return await indApiAdapter.post<QcInfoResponse>({
    endpoint,
    data: Object.fromEntries(flags),
  });
};
export {
  backToDraft,
  finalizeQc,
  getQcInfo,
  submitForQc,
  updateQcFlagsForBlock,
};
