const PageLoading = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <img
        alt="weave-loading"
        width="82px"
        loading="eager"
        decoding="sync"
        src="/images/loading.gif"
      />
    </div>
  );
};

export default PageLoading;
