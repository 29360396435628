export const SupportPopupContainer = ({
  children,
  isOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
}) => {
  return (
    isOpen && (
      <div className="bg-white shadow-lg border border-solid border-red-500 rounded-lg fixed bottom-16 right-5 z-[1001]">
        {children}
      </div>
    )
  );
};
