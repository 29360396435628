import logger from '~/core/providers/logger';
import { WeaveApiResponse } from '~/types/WeaveApiResponse';

export class WeaveResponseHandlers {
  static passFail = async <T>(
    request: Promise<WeaveApiResponse<T>>,
    loggerImpl = logger,
  ) => {
    const response = await request;
    if (response.error) {
      loggerImpl.logError('Error encountered in pass/fail response handler', {
        error: response.error,
      });
      return false;
    }
    return true;
  };

  static data = async <T>(
    request: Promise<WeaveApiResponse<T>>,
    defaultOnError?: T,
    loggerImpl = logger,
  ) => {
    const response = await request;
    if (response.error) {
      loggerImpl.logError('Error encountered in data response handler', {
        error: response.error,
      });
      return defaultOnError;
    }
    return response.data as T;
  };

  static mappedData = async <TOriginalResponse, TDestinationResponse>(
    request: Promise<WeaveApiResponse<TOriginalResponse>>,
    mapper: (data: TOriginalResponse) => TDestinationResponse,
    defaultOnError: TDestinationResponse,
    loggerImpl = logger,
  ): Promise<TDestinationResponse> => {
    const response = await request;
    if (response.error) {
      loggerImpl.logError('Error encountered in mapped data response handler', {
        error: response.error,
      });
      return defaultOnError;
    }
    return mapper(response.data as TOriginalResponse);
  };
}
