// Disabling - this is one of three areas where we directly reference Sentry: two abstractions and one initialization
// a) the Sentry.init
// b) --> [this one] SentryLogProvider
// c) SentryUserReporter
// eslint-disable-next-line local/no-sentry-import
import * as Sentry from '@sentry/react';
import { flatten } from 'flat';

import { LogAreaType, LogProvider } from '../..';

// Note: this provider assumes Sentry has been initialized in main.tsx.  Presumably this logger won't be added
// if Sentry is not enabled, since they're both controlled by the same switch.
export class SentryLogProvider implements LogProvider {
  logArea: (
    area: string,
    areaType: LogAreaType,
    areaCallback: (loggedArea: string) => void,
  ) => void = (
    _area: string,
    _areaType: LogAreaType,
    _areaCallback: (loggedArea: string) => void,
  ) => {
    return; // No-op
  };

  log: (message: string, context?: Record<string, any> | undefined) => void = (
    message,
    context,
  ) => {
    if (context) {
      Sentry.captureMessage(message, { tags: context });
    } else {
      Sentry.captureMessage(message);
    }
  };

  logError: (
    error: string | Error,
    context?: Record<string, any> | undefined,
  ) => void = (error, context) => {
    const reportedError = typeof error === 'string' ? new Error(error) : error;

    if (context) {
      Sentry.withScope((scope) => {
        // @j-weaveL: Sentry can't interpret a nested object structure beyond one
        // level, so we flatten the context object prior to sending just in case
        scope.setContext('Error context', flatten(context));
        Sentry.captureException(reportedError);
      });
    } else {
      Sentry.captureException(reportedError);
    }
  };
}
