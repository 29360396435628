import posthog from 'posthog-js';

import { IndProfile } from '~/core/domain/types';

import { UserReportingProvider } from '..';

export class PosthogUserReportingProvider implements UserReportingProvider {
  identify: ({ uuid, email }: IndProfile) => void = ({ uuid, email }) => {
    posthog.identify(uuid, { email });
  };

  resetIdentification: () => void = () => {
    posthog.reset();
  };
}
