import { RefObject } from 'react';

import { cn } from '~/core/lib/tailwind-util';

import {
  BaseTextProps,
  SupportedHeaderSizes,
  textColorMap,
  textFontWeightMap,
} from '../TextTypes';

type SupportedHeaderElementTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const headerDefinitionMap: Record<
  SupportedHeaderSizes,
  { element: SupportedHeaderElementTypes; textSizeClass: string }
> = {
  subtitle: { element: 'h4', textSizeClass: 'text-[22px] leading-[40px] !m-0' },
  subtitle2: {
    element: 'h5',
    textSizeClass: 'text-[18px] leading-[28px] !m-0',
  },
  subtitle3: {
    element: 'h6',
    textSizeClass: 'text-[16px] leading-[24px] !m-0',
  },
  documentH1: {
    element: 'h1',
    textSizeClass: 'text-[16pt] !m-0',
  },
  documentH2: {
    element: 'h2',
    textSizeClass: '!m-0',
  },
  h3: { element: 'h3', textSizeClass: 'text-4xl leading-[48px] !m-0' },
  h2: { element: 'h2', textSizeClass: 'text-[42px] leading-[64px] !m-0' },
  h1: { element: 'h1', textSizeClass: 'text-[56px] leading-[72px] !m-0' },
};

export interface HeadingProps extends BaseTextProps<SupportedHeaderSizes> {
  ref?: RefObject<HTMLHeadingElement>;
  'data-testid'?: string;
}

export const Heading = ({
  children,
  status = 'default',
  weight = 'semibold',
  italic = false,
  size = 'h3',
  className,
  id,
  ref,
  color,
  onClick,
  'data-testid': dataTestId,
}: HeadingProps) => {
  const { element: HeadingElement, textSizeClass } = headerDefinitionMap[size];
  const semiboldOverride =
    ['subtitle', 'subtitle2'].includes(size) && weight == 'semibold';
  return (
    <HeadingElement
      className={cn(
        color ? color : textColorMap[status],
        textSizeClass,
        semiboldOverride ? 'font-medium' : textFontWeightMap[weight],
        italic ? 'italic' : 'not-italic',
        className,
      )}
      ref={ref}
      id={id}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {children}
    </HeadingElement>
  );
};
