import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { useAuth } from '~/core/contexts/AuthContext'; // Import useAuth hook
import { DefaultLayout } from '~/core/layout/DefaultLayout';
import { IndLayout } from '~/core/layout/IndLayout';
import WeaveSpinner from '~/design-system/components/molecules/WeaveSpinner/WeaveSpinner';
import { privateRoutes } from '~/router/private-routes';
import { publicRoutes } from '~/router/public-routes';

import { FeatureFlaggedRouteWrapper } from './FeatureFlaggedRouteWrapper';
import { NoLayout } from './NoLayout';
import { PrivateRouteWrapper } from './PrivateRouteWrapper';

export const IndRouter = () => {
  const { isAuthenticated, isAuthenticating } = useAuth();

  return isAuthenticating ?
      <div className="w-full h-screen flex justify-center items-center">
        <WeaveSpinner isFullscreen={true} hasIndicator={true} size="xl" />
      </div>
    : <Suspense
        fallback={
          <WeaveSpinner isFullscreen={true} hasIndicator={true} size="xl" />
        }
      >
        <Routes>
          {publicRoutes?.map(({ element, featureFlag, ...rest }) => {
            return (
              <Route
                key={rest.path}
                element={
                  featureFlag ?
                    <FeatureFlaggedRouteWrapper featureFlag={featureFlag}>
                      {element}
                    </FeatureFlaggedRouteWrapper>
                  : element
                }
                {...rest}
              />
            );
          })}
          {isAuthenticated ?
            privateRoutes?.map(
              ({ headerType, element, featureFlag, ...rest }) => {
                let LayoutChoice = NoLayout;
                if (headerType === 'nav') {
                  LayoutChoice = IndLayout;
                } else if (headerType === 'default') {
                  LayoutChoice = DefaultLayout;
                }

                const routeElement = (
                  <PrivateRouteWrapper>
                    <LayoutChoice>{element}</LayoutChoice>
                  </PrivateRouteWrapper>
                );

                return (
                  <Route
                    key={rest.path}
                    element={
                      featureFlag ?
                        <FeatureFlaggedRouteWrapper featureFlag={featureFlag} />
                      : <Outlet />
                    }
                  >
                    <Route element={routeElement} {...rest} />
                  </Route>
                );
              },
            )
          : <></>}
        </Routes>
      </Suspense>;
};
