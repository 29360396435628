import { awsApi } from '~/core/api/clients/aws-api';

export const uploadFileToPreSignedUrl = async (
  body: IPostPreSignedUrlResponse,
) => {
  return awsApi(body.preSignedUrl).put<void>('', body.file, {
    headers: { 'Content-Type': body.file.type },
    timeout: 0, // No timeout
  });
};
