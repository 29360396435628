import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from '~/core/hooks/const';
import { useStrictParams } from '~/core/hooks/core/useStrictParams';
import dayjs from '~/core/lib/dayjs';
import { updateInd } from '~/core/services/IndService';

type Props = {
  onSuccess?: (
    data: IndApplication,
    variables: Partial<IndApplication>,
    context: unknown,
  ) => void;
  onMutate?: (variables: Partial<IndApplication>) => void;
  onError?: (
    error: unknown,
    variables: Partial<IndApplication>,
    context: void | undefined,
  ) => void;
  onSettled?: () => void;
};

/**
 * @deprecated - The pattern of individual hooks per query/mutation predates the Weave FE team.
 * THis approach has been deprecated in favor of "feature"-based hooks (e.g. useRefinement, useOrganization) that
 * combine related queries and mutations as needed.  Please avoid this usage in favor of using the query directly
 * in a feature hook.  This hook will be retired in the future.
 */
export const useUpdateIndDocument = (props: Props | void) => {
  const params = useStrictParams<{ indId: string }>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: Partial<IndApplication>) => {
      request.targetStartDate =
        request.targetStartDate ?
          dayjs(request.targetStartDate).format('YYYY-MM-DD')
        : '';
      request.submissionDateGoal =
        request.submissionDateGoal ?
          dayjs(request.submissionDateGoal).format('YYYY-MM-DD')
        : '';
      return updateInd(params.indId || (request.id as string), request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_IND_APPLICATIONS(),
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_IND_APPLICATION(params.indId),
      });
      queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_STATS,
      });
    },
    onMutate: props?.onMutate,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });
};
