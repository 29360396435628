import './index.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { clsx } from 'clsx';

export type DefaultSpinnerSizes = 'small' | 'default' | 'large';

export interface WeaveSpinnerProps {
  customColorClass?: string;
  hasIndicator?: boolean;
  isFullscreen?: boolean;
  inReview?: boolean;
  size?: DefaultSpinnerSizes | 'xl';
}

const getFontSizeFromSize = (size: string) => {
  const sizes: Record<string, number> = {
    small: 14,
    default: 20,
    large: 32,
    xl: 64,
  };

  return sizes[size];
};

export const WeaveSpinner = ({
  customColorClass,
  hasIndicator = false,
  isFullscreen = false,
  inReview = false,
  size = 'default',
}: WeaveSpinnerProps) => {
  const wrapperClassName = clsx('weave-spinner', {
    'loading-overlay': isFullscreen,
    '': !isFullscreen,
  });

  const colorClassOuter =
    customColorClass ? `[&_.ant-spin-dot-holder]:${customColorClass}`
    : inReview ? '[&_.ant-spin-dot-holder]:text-purple-5'
    : '[&_.ant-spin-dot-holder]:text-weave-color-product-base';

  const colorClassInner =
    customColorClass ? customColorClass
    : inReview ? 'text-purple-5'
    : 'text-weave-color-product-base';

  return (
    <div className={wrapperClassName} data-testid="weaveSpinnerContainer">
      <Spin
        className={!hasIndicator ? colorClassOuter : ''}
        size={size as DefaultSpinnerSizes}
        indicator={
          hasIndicator ?
            <LoadingOutlined
              data-testid="weaveSpinnerInner"
              className={isFullscreen ? 'text-[#fa688f]' : colorClassInner}
              style={{ fontSize: getFontSizeFromSize(size) }}
              spin
            />
          : undefined
        }
        data-testid="weaveSpinnerOuter"
      />
    </div>
  );
};

export default WeaveSpinner;
