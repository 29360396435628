import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { QcProvider } from '~/core/contexts/QcContext/QcProvider';
import { WeaveRoute } from '~/router/types';

export enum PrivateRoutes {
  CONTENT_TEMPLATE = '/content-template/:templateName',
  DASHBOARD = '/dashboard',
  DATA_ROOM = '/data-room/:indId',
  DEVELOPMENT = '/dev',
  DOCUMENT_EDITOR = '/document-editor/:indId/:sectionId',
  EDITOR_PLAYGROUND = '/editor-playground',
  IND_MANAGER = '/ind-manager/:indId',
  MANAGE_TEMPLATES = '/manage-templates',
  ORGANIZATION_MANAGEMENT = '/organization',
  PROFILE = '/profile',
  ROOT = '/',
  TEMPLATE = '/template/:indId',
  TECHNOLOGY = '/technology',
  TERMS_OF_SERVICE = '/terms-of-service',
}

const TechnologyPage = lazy(() => import('~/pages/common/Technology'));
const TermPage = lazy(() => import('~/pages/common/Term'));
const ContentTemplateSpace = lazy(
  () =>
    import('~/features/templates-space/components/pages/ContentTemplateSpace'),
);
const DashboardPage = lazy(
  () =>
    import('~/features/dashboard/components/pages/DashboardPage/DashboardPage'),
);
const DataRoomPage = lazy(() => import('~/pages/private/DataRoomPage'));
const Development = lazy(() => import('~/pages/private/Development'));
const DocumentEditorPageStreamlined = lazy(
  () =>
    import(
      '~/features/document-editor/components/pages/DocumentEditorPageStreamlined/DocumentEditorPageStreamlined'
    ),
);
const IndManagerPage = lazy(() => import('~/pages/private/IndManagerPage'));
const IndTemplatePage = lazy(
  () => import('~/features/templates-space/components/pages/IndTemplatePage'),
);
const ManageTemplatesPage = lazy(
  () =>
    import(
      '~/features/auto-tables/components/pages/ManageTemplatesPage/ManageTemplatesPage'
    ),
);
const OrganizationManagementPage = lazy(
  () =>
    import(
      '~/features/organization-management/components/pages/OrganizationManagementPage/OrganizationManagementPage'
    ),
);
const Page404 = lazy(() => import('~/pages/common/Page404'));
const ProfilePage = lazy(
  () => import('~/features/profile/components/pages/ProfilePage2024'),
);

export const privateRoutes: WeaveRoute[] = [
  {
    path: PrivateRoutes.CONTENT_TEMPLATE,
    element: <ContentTemplateSpace />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.DASHBOARD,
    element: <DashboardPage />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.DATA_ROOM,
    element: <DataRoomPage />,
    headerType: 'nav',
  },
  {
    path: PrivateRoutes.DEVELOPMENT,
    element: <Development />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.DOCUMENT_EDITOR,
    element: (
      <QcProvider>
        <DocumentEditorPageStreamlined />
      </QcProvider>
    ),
    headerType: 'nav',
  },
  {
    path: PrivateRoutes.IND_MANAGER,
    element: <IndManagerPage />,
    headerType: 'nav',
  },
  {
    path: PrivateRoutes.MANAGE_TEMPLATES,
    element: <ManageTemplatesPage />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.ORGANIZATION_MANAGEMENT,
    element: <OrganizationManagementPage />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.PROFILE,
    element: <ProfilePage />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.ROOT,
    element: <Navigate to={PrivateRoutes.DASHBOARD} />,
    headerType: 'default',
  },
  {
    path: PrivateRoutes.TEMPLATE,
    element: <IndTemplatePage sideBarCollapsed={true} />,
    headerType: 'nav',
  },
  {
    path: PrivateRoutes.TECHNOLOGY,
    element: <TechnologyPage />,
    headerType: 'none',
  },
  {
    path: PrivateRoutes.TERMS_OF_SERVICE,
    element: <TermPage />,
    headerType: 'none',
  },
  {
    path: '*',
    element: <Page404 />,
    headerType: 'none',
  },
];
