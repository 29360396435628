import WeaveLogo from '/src/images/weave-logo-dark-blue.svg';

import logger, { LogAreaType } from './core/providers/logger';

export const logSettings = () => {
  if (import.meta.env.VITE_USE_LOCAL_FEATURE_FLAGGING) {
    console.img(WeaveLogo).then(() => {
      logger.logGroup('autoind', LogAreaType.ArchitectureAndSettings, () => {
        logger.log(
          `Feature flagging has been locally overridden in this environment; flag settings are logged below.  If you didn't intend for this to be the case, either remove 'USE_LOCAL_FEATURE_FLAGGING' from your .env file or set it to false.`,
        );
        for (const envSetting in import.meta.env) {
          if (envSetting.startsWith('VITE_FF_')) {
            logger.log(`${envSetting}: ${import.meta.env[envSetting]}`);
          }
        }
      });
    });
  }
};
