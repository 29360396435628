import { SupportedTextColor } from '~/types/tokens/supported-text-color';

export type SupportedTextStatuses = 'default' | 'info';
export type SupportedTextSizes =
  | 'body0'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'mini'
  | 'document';
export type SupportedHeaderSizes =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'subtitle'
  | 'subtitle2'
  | 'subtitle3'
  | 'documentH1'
  | 'documentH2';
export type SupportedFontWeights = 'normal' | 'bold' | 'semibold';

export interface BaseTextProps<TSizeDefinitions> {
  children: string | React.ReactNode;
  status?: SupportedTextStatuses;
  weight?: SupportedFontWeights;
  italic?: boolean;
  size?: TSizeDefinitions;
  color?: SupportedTextColor;
  className?: string;
  id?: string;
  onClick?: () => void;
}

export const textColorMap: Record<SupportedTextStatuses, string> = {
  default: 'text-primary',
  info: 'text-weave-gray',
};

export const textFontWeightMap: Record<SupportedFontWeights, string> = {
  normal: 'font-normal',
  semibold: 'font-semibold',
  bold: 'font-bold',
};
