import { AnchorLinkItemProps, DataNode } from '@weavebio/ui-toolkit';

import { PrivateRoutes } from '~/router/private-routes';

import { isProcessing } from '../services/EntityService';
import { ExtendedTreeDataNode } from './types';

export const findSectionByNumber = (
  sectionNumber: string,
  sections: IndSectionOutline[],
): IndSectionOutline | null => {
  if (!sections) {
    return null;
  }

  for (const section of sections) {
    if (section.section_number === sectionNumber) {
      return section;
    }
    if (section.subsections && section.subsections.length > 0) {
      const foundInSection = findSectionByNumber(
        sectionNumber,
        section.subsections,
      );
      if (foundInSection) {
        return foundInSection;
      }
    }
  }

  return null;
};

export const getSelectedSection = (
  sectionNumber: string,
  indModules: IndModule[],
) => {
  for (const module of indModules) {
    const foundInSection = findSectionByNumber(sectionNumber, module.sections);
    if (foundInSection) {
      return foundInSection;
    }
  }
  return null;
};

export const getAllSubsectionsFlat = (section?: IndSectionOutline | null) => {
  if (!section) {
    return [];
  }

  const result: IndSectionOutline[] = [];

  result.push(section);

  for (const subsection of section.subsections) {
    const subsectionResults = getAllSubsectionsFlat(subsection);
    result.push(...subsectionResults);
  }

  return result;
};

export const getAllSubsectionsFlatFromSectionNumber = (
  sectionNumber: string,
  indModules: IndModule[],
) => {
  const section = getSelectedSection(sectionNumber, indModules);
  if (!section) {
    return [];
  }

  return getAllSubsectionsFlat(section);
};

export const getAllSubsections = (section?: IndSectionOutline | null) => {
  if (!section) {
    return [];
  }

  const result: IndSectionOutline[] = [];

  for (const subsection of section.subsections) {
    const subsectionResults = getAllSubsectionsFlat(subsection);
    result.push(...subsectionResults);
  }

  return result;
};

export const getAllSubsectionsRecursive = (section: IndSectionOutline) => {
  const result: IndSectionOutline[] = [];

  for (const subsection of section.subsections) {
    result.push(subsection);
    if (subsection.subsections?.length) {
      const subsectionResults = getAllSubsectionsRecursive(subsection);
      result.push(...subsectionResults);
    }
  }

  return result;
};
export const getSelectedModule = (
  sectionNumber: string,
  indModules: IndModule[],
) => {
  for (const module of indModules) {
    const foundInSection = findSectionByNumber(sectionNumber, module.sections);
    if (foundInSection) {
      return module;
    }
  }

  return null;
};

export const mapToLinks = (
  subsections: IndSectionOutline[],
): AnchorLinkItemProps[] => {
  return subsections.map((section) => {
    if (!section.subsections) {
      return {
        key: section.section_number,
        href: `#${section.section_number}`,
        title: `${section.section_number} ${section.section_title}`,
        className: section.is_document ? 'disabled' : '',
      };
    }

    return {
      key: section.section_number,
      href: `#${section.section_number}`,
      title: `${section.section_number} ${section.section_title}`,
      className: section.is_document ? 'disabled' : '',
      children: mapToLinks(section.subsections),
    };
  });
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const htmlEncodeToUnicode = (input: string) => {
  const encoded = input.split('<br>').join('\n').replaceAll('&amp;', '&');
  return encoded;
};

export const htmlEncodeToDecimal = (input: string) => {
  const encoded = input.replace(
    /[\u00A0-\u9999<>&]/g,
    (i) => `&#${i.charCodeAt(0)};`,
  );
  return encoded;
};

export const htmlMap = (
  content: Block[] | undefined,
  sectionNumber: string,
) => {
  if (!content?.length) return '';

  let text = '';

  content.forEach((item) => {
    const copyText = htmlDecode(item?.texts?.map((text) => text.text).join(''));
    const blockId = item.block_id;
    const parsedText = copyText?.replace(/<[^/][^>]*?>/g, (tag) => {
      return tag
        .replace(
          '>',
          ` data-block-id="${blockId}" data-section-number="${sectionNumber}">`,
        )
        .replace(/\\/g, '');
    });

    text += parsedText;
  });

  return text;
};

export const getFirstParentIndDoc = (
  section: IndSectionOutline | undefined,
  indModule: IndModule[],
): IndSectionOutline | null => {
  if (!section) {
    return null;
  }

  if (section.is_document) {
    return section;
  }

  const parentSectionNumber = section.section_number
    .split('.')
    .slice(0, -1)
    .join('.');
  const parentSection = getSelectedSection(parentSectionNumber, indModule);
  if (!parentSection) {
    return null;
  }

  if (parentSection.is_document) {
    return parentSection;
  }

  return getFirstParentIndDoc(parentSection, indModule);
};

export const getFirstParentIndDocFromSectionNumber = (
  sectionNumber: string,
  indModule: IndModule[],
): IndSectionOutline | null => {
  const currentSection = getSelectedSection(sectionNumber, indModule);
  if (!currentSection) {
    return null;
  }

  if (currentSection.is_document) {
    return currentSection;
  }

  const parentSectionNumber = currentSection.section_number
    .split('.')
    .slice(0, -1)
    .join('.');

  return getFirstParentIndDocFromSectionNumber(parentSectionNumber, indModule);
};

export const getAllParentSections = (
  section: IndSectionOutline | undefined,
  indModule: IndModule[],
): IndSectionOutline[] => {
  if (!section) {
    return [];
  }

  const parentSectionNumber = section.section_number
    .split('.')
    .slice(0, -1)
    .join('.');
  const parentSection = getSelectedSection(parentSectionNumber, indModule);
  if (!parentSection) {
    return [];
  }

  return [parentSection, ...getAllParentSections(parentSection, indModule)];
};

export const isAnyOfTheParentIsDocument = (
  section: IndSectionOutline | undefined,
  indModule: IndModule[],
): boolean => {
  if (!section) {
    return false;
  }

  if (section.is_document) {
    return true;
  }

  const parentSectionNumber = section.section_number
    .split('.')
    .slice(0, -1)
    .join('.');
  const parentSection = getSelectedSection(parentSectionNumber, indModule);
  if (!parentSection) {
    return false;
  }

  return isAnyOfTheParentIsDocument(parentSection, indModule);
};

export const getAllParents = (
  section: IndSectionOutline | undefined,
  indModule: IndModule[],
): (IndSectionOutline | IndModule)[] => {
  if (!section) {
    return [];
  }

  const parentSectionNumber = section.section_number
    .split('.')
    .slice(0, -1)
    .join('.');

  if (parentSectionNumber.length === 1) {
    // means it is a module, also include the module
    const module = indModule.find(
      (module) => module.module_number === parentSectionNumber,
    );
    if (!module) {
      return [];
    }

    return [module];
  }
  const parentSection = getSelectedSection(parentSectionNumber, indModule);
  if (!parentSection) {
    return [];
  }

  return [parentSection, ...getAllParents(parentSection, indModule)];
};

export const findNodeBySectionId = (
  sectionId: string,
  treeData: ExtendedTreeDataNode[],
): ExtendedTreeDataNode | undefined => {
  for (const data of treeData) {
    if (data.key === sectionId) {
      return data as any;
    } else {
      const response = findNodeBySectionId(
        sectionId,
        (data?.children as any) ?? [],
      ) as any;
      if (response) {
        return response;
      }
    }
  }
  return undefined;
};

export const hasAnyOfTheSubsectionsDocumentAndSupported = (
  indModule: IndModule,
) => {
  const isDocAndSupported = indModule.sections.some((section) => {
    const docAndSupported = section.is_document && section.is_supported;
    if (docAndSupported) {
      return true;
    }

    if (section.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsDocumentAndSupported(section);
    }

    return false;
  });

  return isDocAndSupported;
};

export const isItselfOrAnyOfTheChildrenGenerating = (
  section: IndSectionOutline,
) => {
  if (isProcessing(section)) {
    return true;
  }

  const isGenerating = section.subsections.some((subsection) => {
    const isGenerating = isProcessing(subsection);
    if (isGenerating) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheChildrenGenerating(subsection);
    }

    return false;
  });

  return isGenerating;
};

export const isAnyOfTheSubsectionsReady = (section: IndSectionOutline) => {
  const isReady = section.subsections.some((subsection) => {
    const isItReady = subsection.status === 'Ready';
    if (isItReady) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsReady(subsection);
    }

    return false;
  });

  return isReady;
};

export const isItselfOrAnyOfTheSubsectionsReady = (
  section: IndSectionOutline,
) => {
  if (section.status === 'Ready') {
    return true;
  }

  const isReady = section.subsections.some((subsection) => {
    const isItReady = subsection.status === 'Ready';
    if (isItReady) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsReady(subsection);
    }

    return false;
  });

  return isReady;
};

export const isItselfOrAnyOfTheSubsectionsEmpty = (
  section: IndSectionOutline,
) => {
  if (section.status === 'Empty') {
    return true;
  }

  const isEmpty = section?.subsections.some((subsection) => {
    const isGenerating = subsection.status === 'Empty';
    if (isGenerating) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsEmpty(subsection);
    }

    return false;
  });

  return isEmpty;
};

export const isItselfOrAnyOfTheSubsectionsFailed = (
  section: IndSectionOutline,
) => {
  if (section.failed === true) {
    return true;
  }

  const isFailed = section?.subsections.some((subsection) => {
    const isSubsectionFailed = subsection.failed === true;
    if (isSubsectionFailed) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsEmpty(subsection);
    }

    return false;
  });

  return isFailed;
};

export const isItselfOrAnyOfTheSubsectionsDocumentAndSupported = (
  section: IndSectionOutline,
) => {
  if (section.is_document && section.is_supported) {
    return true;
  }

  const isDocAndSupported = section.subsections.some((subsection) => {
    const docAndSupported = subsection.is_document && subsection.is_supported;
    if (docAndSupported) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsDocumentAndSupported(subsection);
    }

    return false;
  });

  return isDocAndSupported;
};

export const isItselfOrAnyOfTheSubsectionsSupported = (
  section: IndSectionOutline,
) => {
  if (section.is_supported) {
    return true;
  }

  const isSupported = section.subsections?.some((subsection) => {
    const isSupported = subsection.is_supported;
    if (isSupported) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isItselfOrAnyOfTheSubsectionsSupported(subsection);
    }

    return false;
  });

  return isSupported;
};

export const isAnyOfTheSubsectionsGenerating = (section: IndSectionOutline) => {
  const isGenerating = section.subsections.some((subsection) => {
    const isGenerating = isProcessing(section);
    if (isGenerating) {
      return true;
    }

    if (subsection.subsections?.length) {
      return isAnyOfTheSubsectionsGenerating(subsection);
    }

    return false;
  });

  return isGenerating;
};

export const getModeFromSection = (
  section: IndSectionOutline | null,
): GenerateMode => {
  if (!section) {
    return null;
  }

  if (
    section.doc_types.length < 1 &&
    section.dependent_sections.length < 1 &&
    section.is_document === true &&
    !section.is_dependent_node &&
    section.is_terminal === true
  ) {
    return 0;
  }

  if (!section.is_dependent_node && section.is_terminal) {
    return 1;
  }

  if (section.is_dependent_node && section.is_terminal) {
    return 2;
  }

  if (section.is_dependent_node && !section.is_terminal) {
    return 3;
  }

  if (!section.is_dependent_node && !section.is_terminal) {
    return 3;
  }

  return 1;
};

export const getModeFromSectionNumber = (
  sectionNumber: string,
  indModules: IndModule[],
): GenerateMode => {
  if (!sectionNumber) {
    return null;
  }

  const section = getSelectedSection(sectionNumber, indModules);
  return getModeFromSection(section);
};

export const getBgColorForSection = (section?: IndSectionOutline) => {
  if (!section) {
    return '';
  }

  if (section.is_document && section.is_supported) {
    return `bg-[#BAE0FF] px-1 rounded`;
  }

  if (section.is_document && !section.is_supported) {
    return `bg-[#E6F4FF] px-1 rounded text-weave-gray `;
  }

  return '';
};

export const generateEmptyReferenceText = (
  referenceType: ReferenceType | '',
  key?: string,
) => {
  const typeText =
    referenceType === 'source-document' ? 'source documents'
    : referenceType === 'fda-guidance' ? 'FDA guidances'
    : referenceType === 'application-variable' ? 'IND Section'
    : 'references';
  return (
    <span key={key} className="empty-reference-text py-4 px-8">
      Your document does not contain any linked {typeText}.
    </span>
  );
};

export const generateEmptyReferenceTextForSelected = (
  referenceType: ReferenceType | '',
  key?: string,
) => {
  const typeText =
    referenceType === 'source-document' ? 'source documents'
    : referenceType === 'fda-guidance' ? 'FDA guidances'
    : referenceType === 'application-variable' ? 'IND Section'
    : 'references';
  return (
    <span key={key} className="empty-reference-text py-2 px-8">
      Your selection does not contain any linked {typeText}.
    </span>
  );
};

export const getModuleForSection = (section: IndSectionOutline) => {
  const module = section.section_number.split('.')[0];
  const moduleNumber = Number.parseInt(module);

  return moduleNumber;
};

export const convertFoldersToTreeStructure = (
  data: SourceFolder[],
): DataNode[] => {
  const map = new Map<string, DataNode>();

  data.forEach(({ id, name, parentFolderId }) => {
    map.set(id, { key: id, title: name });
    if (parentFolderId !== null) {
      const parentNode = map.get(parentFolderId);
      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = [];
        }
        parentNode.children.push(map.get(id)!);
      }
    }
  });

  const roots: DataNode[] = [];
  map.forEach((value) => {
    if (
      data.find((item) => item.id === value.key && item.parentFolderId === null)
    ) {
      roots.push(value);
    }
  });

  //add root folder
  return [{ key: 'root', title: 'Home', children: roots }];

  // return roots;
};

/**
 * Builds a URL that can be used to navigate to the target section in the context of the document editor.
 */
export const getEditorViewableUrlForSection = ({
  section,
  indId,
  indApp,
}: {
  section: IndSectionOutline;
  indId: string;
  indApp: IndApplication;
}) => {
  const parentDocument = getFirstParentIndDoc(section, indApp?.modules ?? []);

  if (section.is_document) {
    return PrivateRoutes.DOCUMENT_EDITOR.replace(':indId', indId).replace(
      ':sectionId',
      `${section?.section_number}#${section?.section_number}`,
    );
  }
  return PrivateRoutes.DOCUMENT_EDITOR.replace(':indId', indId).replace(
    ':sectionId',
    `${parentDocument?.section_number}#${section.section_number}`,
  );
};
