import { getCurrentUserSessionSession } from '~/core/services/UserService';
import { useSessionStore } from '~/core/store/sessions-store';

import * as UserService from '../../core/services/UserService';

export const refreshSession = async () => {
  const session = await getCurrentUserSessionSession();
  useSessionStore.getState().setSession(session);
  if (session?.user) {
    const userProfile = await UserService.getProfile();
    UserService.identifyUser(userProfile);
  } else {
    UserService.resetIdentification();
  }
  return session;
};
