import { Dayjs } from 'dayjs';

export type IndInvitation = {
  id: string;
  orgId: string;
  orgName: string;
  email: string;
};

export type IndInvitationResult = {
  id: string;
  orgId: string;
  orgName: string;
  email: string;
  result?: 'success' | 'error' | 'already-invited';
};

export enum OrganizationRole {
  Admin = 'org_admin',
  Member = 'org_member',
}

// This is distinct from IndUser (although at some point we'll likely harmonize these types).
export type OrganizationMember = {
  uuid: string;
  email: string;
  role: OrganizationRole;
  firstName: string;
  lastName: string;
  friendlyName: string;
  status: 'pending' | 'accepted' | 'invalid';
  assignedInds: number;
  isActive: boolean;
  invitationId: string;
};

export type Organization = {
  id: string;
  slug: string;
  name: string;
  type: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  members: Array<OrganizationMember>;
};

export type OrganizationStats = {
  name: string;
  totalInds: number;
  totalUsers: number;
};
