import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { DateUtils } from '~/core/lib/DateUtils';
import logger from '~/core/providers/logger';
import {
  OrganizationMember,
  OrganizationStats,
} from '~/features/organization-management/domain/types';
import { Organization } from '~/features/organization-management/domain/types';

import { OrganizationServiceMapper } from './Response/mappers';
import {
  GetOrganizationDetailsResponse,
  GetOrganizationIndsResponse,
} from './Response/types';

const organizationServiceEndpoints = {
  getMembers: (orgId: string) => `organization/${orgId}/members`,
  getOrganization: `organization/detail/`,
  removeMember: `organization/remove-member/`,
  getOrganizationInds: `organization/inds/`,
};

const indApiAdapter = new IndHttpAdapter();

const getMembers: () => Promise<OrganizationMember[]> = async () => {
  const membersResponse =
    await indApiAdapter.get<GetOrganizationDetailsResponse>({
      endpoint: organizationServiceEndpoints.getOrganization,
    });

  if (membersResponse.error) {
    logger.logError(membersResponse.error.message, {
      ...membersResponse.error,
    });
    return [];
  }

  const orgDetails =
    OrganizationServiceMapper.map.getOrganizationDetailResponse.to.organization(
      membersResponse.data as GetOrganizationDetailsResponse,
    );
  return orgDetails.members.filter(
    (member) => !['invalid', 'rescinded'].includes(member.status),
  );
};

const getOrganization: () => Promise<Organization> = async () => {
  const orgResponse = await indApiAdapter.get({
    endpoint: organizationServiceEndpoints.getOrganization,
  });
  if (orgResponse.error) {
    logger.logError(orgResponse.error.message, { ...orgResponse.error });
    return {
      id: '',
      slug: '',
      name: '',
      type: '',
      createdAt: DateUtils.null(),
      updatedAt: DateUtils.null(),
      members: [],
    };
  }
  const organization =
    OrganizationServiceMapper.map.getOrganizationDetailResponse.to.organization(
      orgResponse.data as GetOrganizationDetailsResponse,
    );
  return organization;
};

const getOrgStats: () => Promise<OrganizationStats> = async () => {
  const getOrgIndsPromise = indApiAdapter.get<GetOrganizationIndsResponse>({
    endpoint: organizationServiceEndpoints.getOrganizationInds,
  });
  const getOrgPromise = getOrganization();
  const results = await Promise.all([getOrgIndsPromise, getOrgPromise]);
  if (results[0].error) {
    logger.logError(results[0].error.message, {
      ...results[0].error,
    });
    return {
      name: results[1].name,
      totalInds: 0,
      totalUsers: results[1].members.length,
    };
  }
  return {
    totalInds: (results[0].data as GetOrganizationIndsResponse).inds.length,
    totalUsers: results[1].members.length,
    name: results[1].name,
  };
};

const removeUser: ({
  userId,
}: {
  userId: string;
}) => Promise<boolean> = async ({ userId }) => {
  const removeUserResponse = await indApiAdapter.post({
    endpoint: organizationServiceEndpoints.removeMember,
    data: {
      user_id: userId,
    },
  });

  if (removeUserResponse.error) {
    logger.logError(removeUserResponse.error.message, {
      ...removeUserResponse.error,
    });
    return false;
  }

  return true;
};

export { getMembers, getOrganization, getOrgStats, removeUser };
