import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import Page500 from '~/pages/common/Page500';

import logger from '../logger';

type Props = {
  children: React.ReactNode;
};

export const GlobalErrorBoundary = (props: Props) => {
  return (
    <ReactErrorBoundary
      onError={(err, _info) => {
        logger.logError(
          `Error in global error boundary: ${JSON.stringify(err)}`,
          { err },
        );
      }}
      fallbackRender={({ resetErrorBoundary }) => {
        return <Page500 resetErrorBoundary={resetErrorBoundary} {...props} />;
      }}
    >
      {props.children}
    </ReactErrorBoundary>
  );
};
