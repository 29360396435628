import { DateUtils } from '~/core/lib/DateUtils';
import { FriendlyNameMapper } from '~/core/mappers/friendlyNameMapper';
import { Organization } from '~/features/organization-management/domain/types';

import { GetOrganizationDetailsResponse } from './types';

const mapGetOrganizationDetailsResponseToOrganization = (
  apiResponse: GetOrganizationDetailsResponse,
): Organization => {
  return {
    id: apiResponse.uuid,
    createdAt: DateUtils.fromUtcString(apiResponse.created_at),
    updatedAt: DateUtils.fromUtcString(apiResponse.updated_at),
    slug: apiResponse.slug,
    name: apiResponse.name,
    type: apiResponse.type,
    members: apiResponse.members.map((apiMember) => {
      return {
        uuid: apiMember.uuid,
        email: apiMember.email,
        firstName: apiMember.first_name,
        lastName: apiMember.last_name,
        friendlyName:
          FriendlyNameMapper.map.profileResponseData.to.friendlyName(apiMember),
        invitationId: apiMember.invitation_id,
        role: apiMember.role,
        status: apiMember.invitation_status,
        isActive: apiMember.is_active,
        assignedInds: 0, // Note: this is not currently returned in the payload
      };
    }),
  };
};

export const OrganizationServiceMapper = {
  map: {
    getOrganizationDetailResponse: {
      to: {
        organization: mapGetOrganizationDetailsResponseToOrganization,
      },
    },
  },
};
