import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import RelativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(RelativeTime);
dayjs.extend(utc);

export class DateUtils {
  static fromUtcString(val: string) {
    return dayjs.utc(val);
  }

  // Special case handling for when a date is intended to represent a null value.
  static null() {
    return dayjs.utc(Date.UTC(1000, 1, 1));
  }

  static utcMin() {
    // https://stackoverflow.com/questions/11526504/minimum-and-maximum-date
    return dayjs.utc(Date.UTC(1970, 1, 1));
  }

  static utcMax() {
    // https://stackoverflow.com/questions/11526504/minimum-and-maximum-date
    return dayjs.utc(Date.UTC(9999, 1, 1));
  }

  static utcNow() {
    return dayjs.utc();
  }
}
