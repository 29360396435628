import './index.scss';

import { Dropdown } from '@weavebio/ui-toolkit';
import { Suspense, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '~/core/contexts/AuthContext';
import UserContext from '~/core/contexts/UserContext';
import { useIsFeatureEnabled } from '~/core/hooks/core/useIsFeatureEnabled';
import { useToast } from '~/core/hooks/core/useToast.tsx';
import { useGetSession } from '~/core/store/sessions-store';
import { ScrollToTop } from '~/design-system/components/atoms/ScrollToTop/ScrollToTop';
import { BodyText } from '~/design-system/components/atoms/Text/Body';
import WeaveSpinner from '~/design-system/components/molecules/WeaveSpinner/WeaveSpinner';
import { Support } from '~/design-system/components/organisms/Support';
import { PrivateRoutes } from '~/router/private-routes';
import { PublicRoutes } from '~/router/public-routes';

interface Props {
  children: React.ReactNode;
}

export const DefaultLayout = (props: Props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const location = useLocation();
  const { userProfile } = useContext(UserContext);
  const { logout } = useAuth();
  const allowTemplateManagement = useIsFeatureEnabled(
    'fe-allow-template-management',
  );

  useEffect(() => {
    return () => {
      const el = document.getElementById('default-layout');
      if (el) {
        el.style.backgroundImage = 'none';
      }
    };
  }, [location]);

  const dropdownItems = [
    {
      key: '1',
      onClick: () => navigate(PrivateRoutes.PROFILE),
      label: 'Account Settings',
    },
    allowTemplateManagement ?
      {
        key: 'tm',
        label: 'Template Management',
        onClick: () => navigate(PrivateRoutes.MANAGE_TEMPLATES),
      }
    : undefined,
    {
      key: '2',
      onClick: async () => {
        // See also IndLayout.dropDownItems
        await logout();
        navigate(PublicRoutes.SIGN_IN);
        toast.success({
          message: 'You have been signed out successfully',
        });
      },
      label: 'Log Out',
    },
  ].filter(Boolean) as Array<{
    key: string;
    label: string;
    onClick: () => void;
  }>;

  const session = useGetSession();

  return (
    <>
      <ScrollToTop />

      <div className="h-full" id="default-layout">
        <div className="w-full fixed py-2 h-[45px] z-10 left-0 top-0 flex items-center justify-between border-b border-solid border-b-weave-primary-400 bg-white">
          <div className="flex justify-start items-center">
            <div className="h-full flex justify-center items-center px-4 mt-[7px]">
              <Link to={PrivateRoutes.DASHBOARD}>
                <img
                  alt="weave"
                  width="32px"
                  loading="eager"
                  decoding="sync"
                  src="/images/weave-logo-dark-blue.svg"
                />
              </Link>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <Dropdown
              trigger={['click']}
              className="p-0 bg-none border-none outline-none"
              menu={{
                items: dropdownItems,
              }}
              placement="bottomLeft"
            >
              <button
                className="unstyled-button h-full flex justify-center items-center px-4 border-l border-solid border-l-weave-primary-400"
                onClick={(e) => e.preventDefault()}
              >
                <BodyText
                  size="mini"
                  weight="semibold"
                  color="text-weave-primary"
                >
                  {userProfile.friendlyName !== '' ?
                    userProfile.friendlyName
                  : session?.user?.name ?? 'Menu'}
                </BodyText>
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="pt-11">
          <Support />
          <Suspense
            fallback={
              <WeaveSpinner isFullscreen={true} hasIndicator={true} size="xl" />
            }
          >
            {props.children}
          </Suspense>
        </div>
      </div>
    </>
  );
};
