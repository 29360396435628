import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { refreshSession } from '~/core/api/session-manager.ts';
import { AuthenticatedUser } from '~/core/domain/types.ts';
import { useToast } from '~/core/hooks/core/useToast';
import logger from '~/core/providers/logger';
import { login, logout } from '~/core/services/AuthService';

interface LoginDataType {
  email: string;
  password: string;
}

interface LoginResponsePayload {
  session?: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  sessionString: string | null;
  login: (data: LoginDataType) => Promise<LoginResponsePayload | undefined>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isAuthenticating: true,
  sessionString: null,
  login: async (_data: LoginDataType): Promise<LoginResponsePayload> => {
    throw new Error('login function is not implemented');
  },
  logout: async () => {},
});

type AuthProviderProps = PropsWithChildren<any>;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [sessionString, setSessionString] = useState<string | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);

  const toast = useToast();

  /**
   * Identifies the user session for analytics and tracking purposes.
   */
  const handleLogin = async (
    data: LoginDataType,
  ): Promise<LoginResponsePayload | undefined> => {
    const authenticatedUser: AuthenticatedUser | null = await login(data);

    if (authenticatedUser === null) {
      toast.error({ message: 'Authentication failed. Please try again' });
      return undefined;
    } else {
      // Authenticated successfully
      localStorage.setItem('sessionToken', authenticatedUser.session);
      await refreshSession();
      setIsAuthenticated(true);
      setSessionString(authenticatedUser.session);
      return { session: authenticatedUser.session };
    }
  };

  useEffect(() => {
    // This effect runs on mount to check if the user is already authenticated
    // based on the presence of a session token in localStorage
    const sessionString = localStorage.getItem('sessionToken');
    if (sessionString) {
      setIsAuthenticated(true);
      setSessionString(sessionString);
    } else {
      setIsAuthenticated(false);
      setSessionString(null);
    }
    setIsAuthenticating(false);
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      setIsAuthenticated(false);
      setSessionString(null);
    } catch (error) {
      logger.logError('Logout failed', { error });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        isAuthenticated,
        sessionString,
        login: handleLogin,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
