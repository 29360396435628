import { createContext } from 'react';

import { QcData } from '~/features/better-qc/types/qc-types';

type QcContextType = {
  qcInfo: QcData;
  setQcInfo: (qcInfo: QcData) => void;
  refreshQcInfo: () => Promise<QcData | undefined>;
  isLoading: boolean;
};

const QcContext = createContext<QcContextType>({
  qcInfo: {
    id: '',
    reviewers: [],
    flags: [],
  },
  setQcInfo: () => {},
  refreshQcInfo: () => new Promise(() => {}),
  isLoading: false,
});

export { QcContext };
