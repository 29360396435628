import { ThemeConfig } from 'antd';

// export const tokens = {
//   'light/colors': {
//     blue: {
//       '1': {
//         value: '#eaf0fa',
//         type: 'color',
//       },
//       '2': {
//         value: '#c3d6f3',
//         type: 'color',
//       },
//       '3': {
//         value: '#9fbeef',
//         type: 'color',
//       },
//       '4': {
//         value: '#8CB1ED',
//         type: 'color',
//       },
//       '5': {
//         value: '#5890e9',
//         type: 'color',
//       },
//       '6': {
//         value: '#3175e3',
//         type: 'color',
//       },
//       '7': {
//         value: '#195cc8',
//         type: 'color',
//       },
//       '8': {
//         value: '#1249a1',
//         type: 'color',
//       },
//       '9': {
//         value: '#0c387d',
//         type: 'color',
//       },
//       '10': {
//         value: '#062960',
//         type: 'color',
//       },
//     },
//     purple: {
//       '1': {
//         value: '#f9f0ff',
//         type: 'color',
//       },
//       '2': {
//         value: '#efdbff',
//         type: 'color',
//       },
//       '3': {
//         value: '#d3adf7',
//         type: 'color',
//       },
//       '4': {
//         value: '#b37feb',
//         type: 'color',
//       },
//       '5': {
//         value: '#9254de',
//         type: 'color',
//       },
//       '6': {
//         value: '#722ed1',
//         type: 'color',
//       },
//       '7': {
//         value: '#531dab',
//         type: 'color',
//       },
//       '8': {
//         value: '#391085',
//         type: 'color',
//       },
//       '9': {
//         value: '#22075e',
//         type: 'color',
//       },
//       '10': {
//         value: '#120338',
//         type: 'color',
//       },
//     },
//     cyan: {
//       '1': {
//         value: '#eef6f6',
//         type: 'color',
//       },
//       '2': {
//         value: '#c8e5e4',
//         type: 'color',
//       },
//       '3': {
//         value: '#9cd3d3',
//         type: 'color',
//       },
//       '4': {
//         value: '#86CDCC',
//         type: 'color',
//       },
//       '5': {
//         value: '#4eb7b5',
//         type: 'color',
//       },
//       '6': {
//         value: '#3e9897',
//         type: 'color',
//       },
//       '7': {
//         value: '#2b7875',
//         type: 'color',
//       },
//       '8': {
//         value: '#1c5958',
//         type: 'color',
//       },
//       '9': {
//         value: '#103c3b',
//         type: 'color',
//       },
//       '10': {
//         value: '#082121',
//         type: 'color',
//       },
//     },
//     green: {
//       '1': {
//         value: '#f8fcf3',
//         type: 'color',
//       },
//       '2': {
//         value: '#deeec9',
//         type: 'color',
//       },
//       '3': {
//         value: '#c1dd9d',
//         type: 'color',
//       },
//       '4': {
//         value: '#adcd83',
//         type: 'color',
//       },
//       '5': {
//         value: '#8fbe50',
//         type: 'color',
//       },
//       '6': {
//         value: '#77a63a',
//         type: 'color',
//       },
//       '7': {
//         value: '#5d8627',
//         type: 'color',
//       },
//       '8': {
//         value: '#426218',
//         type: 'color',
//       },
//       '9': {
//         value: '#2c4110',
//         type: 'color',
//       },
//       '10': {
//         value: '#162207',
//         type: 'color',
//       },
//     },
//     magenta: {
//       '1': {
//         value: '#fff0f6',
//         type: 'color',
//       },
//       '2': {
//         value: '#ffd6e7',
//         type: 'color',
//       },
//       '3': {
//         value: '#ffadd2',
//         type: 'color',
//       },
//       '4': {
//         value: '#ff85c0',
//         type: 'color',
//       },
//       '5': {
//         value: '#f759ab',
//         type: 'color',
//       },
//       '6': {
//         value: '#eb2f96',
//         type: 'color',
//       },
//       '7': {
//         value: '#c41d7f',
//         type: 'color',
//       },
//       '8': {
//         value: '#9e1068',
//         type: 'color',
//       },
//       '9': {
//         value: '#780650',
//         type: 'color',
//       },
//       '10': {
//         value: '#520339',
//         type: 'color',
//       },
//     },
//     red: {
//       '1': {
//         value: '#fff1f0',
//         type: 'color',
//       },
//       '2': {
//         value: '#ffccc7',
//         type: 'color',
//       },
//       '3': {
//         value: '#ffa39e',
//         type: 'color',
//       },
//       '4': {
//         value: '#ff7875',
//         type: 'color',
//       },
//       '5': {
//         value: '#ff4d4f',
//         type: 'color',
//       },
//       '6': {
//         value: '#f5222d',
//         type: 'color',
//       },
//       '7': {
//         value: '#cf1322',
//         type: 'color',
//       },
//       '8': {
//         value: '#a8071a',
//         type: 'color',
//       },
//       '9': {
//         value: '#820014',
//         type: 'color',
//       },
//       '10': {
//         value: '#5c0011',
//         type: 'color',
//       },
//     },
//     orange: {
//       '1': {
//         value: '#fff1e5',
//         type: 'color',
//       },
//       '2': {
//         value: '#ffd8b8',
//         type: 'color',
//       },
//       '3': {
//         value: '#ffc18f',
//         type: 'color',
//       },
//       '4': {
//         value: '#FFB272',
//         type: 'color',
//       },
//       '5': {
//         value: '#ff9a47',
//         type: 'color',
//       },
//       '6': {
//         value: '#fa7c14',
//         type: 'color',
//       },
//       '7': {
//         value: '#d46308',
//         type: 'color',
//       },
//       '8': {
//         value: '#ad4e00',
//         type: 'color',
//       },
//       '9': {
//         value: '#853c00',
//         type: 'color',
//       },
//       '10': {
//         value: '#612c00',
//         type: 'color',
//       },
//     },
//     yellow: {
//       '1': {
//         value: '#feffe6',
//         type: 'color',
//       },
//       '2': {
//         value: '#ffffb8',
//         type: 'color',
//       },
//       '3': {
//         value: '#fffb8f',
//         type: 'color',
//       },
//       '4': {
//         value: '#fff566',
//         type: 'color',
//       },
//       '5': {
//         value: '#ffec3d',
//         type: 'color',
//       },
//       '6': {
//         value: '#fadb14',
//         type: 'color',
//       },
//       '7': {
//         value: '#d4b106',
//         type: 'color',
//       },
//       '8': {
//         value: '#ad8b00',
//         type: 'color',
//       },
//       '9': {
//         value: '#876800',
//         type: 'color',
//       },
//       '10': {
//         value: '#614700',
//         type: 'color',
//       },
//     },
//     volcano: {
//       '1': {
//         value: '#fff2e8',
//         type: 'color',
//       },
//       '2': {
//         value: '#ffd8bf',
//         type: 'color',
//       },
//       '3': {
//         value: '#ffbb96',
//         type: 'color',
//       },
//       '4': {
//         value: '#ff9c6e',
//         type: 'color',
//       },
//       '5': {
//         value: '#ff7a45',
//         type: 'color',
//       },
//       '6': {
//         value: '#fa541c',
//         type: 'color',
//       },
//       '7': {
//         value: '#d4380d',
//         type: 'color',
//       },
//       '8': {
//         value: '#ad2102',
//         type: 'color',
//       },
//       '9': {
//         value: '#871400',
//         type: 'color',
//       },
//       '10': {
//         value: '#610b00',
//         type: 'color',
//       },
//     },
//     geekblue: {
//       '1': {
//         value: '#f0f5ff',
//         type: 'color',
//       },
//       '2': {
//         value: '#d6e4ff',
//         type: 'color',
//       },
//       '3': {
//         value: '#adc6ff',
//         type: 'color',
//       },
//       '4': {
//         value: '#85a5ff',
//         type: 'color',
//       },
//       '5': {
//         value: '#597ef7',
//         type: 'color',
//       },
//       '6': {
//         value: '#2f54eb',
//         type: 'color',
//       },
//       '7': {
//         value: '#1d39c4',
//         type: 'color',
//       },
//       '8': {
//         value: '#10239e',
//         type: 'color',
//       },
//       '9': {
//         value: '#061178',
//         type: 'color',
//       },
//       '10': {
//         value: '#030852',
//         type: 'color',
//       },
//     },
//     lime: {
//       '1': {
//         value: '#fcffe6',
//         type: 'color',
//       },
//       '2': {
//         value: '#f4ffb8',
//         type: 'color',
//       },
//       '3': {
//         value: '#eaff8f',
//         type: 'color',
//       },
//       '4': {
//         value: '#d3f261',
//         type: 'color',
//       },
//       '5': {
//         value: '#bae637',
//         type: 'color',
//       },
//       '6': {
//         value: '#a0d911',
//         type: 'color',
//       },
//       '7': {
//         value: '#7cb305',
//         type: 'color',
//       },
//       '8': {
//         value: '#5b8c00',
//         type: 'color',
//       },
//       '9': {
//         value: '#3f6600',
//         type: 'color',
//       },
//       '10': {
//         value: '#254000',
//         type: 'color',
//       },
//     },
//     gold: {
//       '1': {
//         value: '#fff9e5',
//         type: 'color',
//       },
//       '2': {
//         value: '#ffedb8',
//         type: 'color',
//       },
//       '3': {
//         value: '#ffe38f',
//         type: 'color',
//       },
//       '4': {
//         value: '#FFDA6B',
//         type: 'color',
//       },
//       '5': {
//         value: '#ffcf3d',
//         type: 'color',
//       },
//       '6': {
//         value: '#fac114',
//         type: 'color',
//       },
//       '7': {
//         value: '#d5a107',
//         type: 'color',
//       },
//       '8': {
//         value: '#ad8200',
//         type: 'color',
//       },
//       '9': {
//         value: '#856300',
//         type: 'color',
//       },
//       '10': {
//         value: '#614700',
//         type: 'color',
//       },
//     },
//     coral: {
//       '1': {
//         value: '#FEE1E2',
//         type: 'color',
//       },
//       '2': {
//         value: '#FDC3C5',
//         type: 'color',
//       },
//       '3': {
//         value: '#FCA4A9',
//         type: 'color',
//       },
//       '4': {
//         value: '#FB868C',
//         type: 'color',
//       },
//       '5': {
//         value: '#FA686F',
//         type: 'color',
//         description: "weave's primary accent color",
//       },
//       '6': {
//         value: '#c7484e',
//         type: 'color',
//       },
//       '7': {
//         value: '#8a3136',
//         type: 'color',
//       },
//       '8': {
//         value: '#6b2527',
//         type: 'color',
//       },
//       '9': {
//         value: '#441618',
//         type: 'color',
//       },
//       '10': {
//         value: '#321516',
//         type: 'color',
//       },
//     },
//     neutral: {
//       '1': {
//         value: '#FFFFFF',
//         type: 'color',
//       },
//       '2': {
//         value: '#e8e9ee',
//         type: 'color',
//       },
//       '3': {
//         value: '#c1c6d1',
//         type: 'color',
//       },
//       '4': {
//         value: '#959baa',
//         type: 'color',
//       },
//       '5': {
//         value: '#6c7486',
//         type: 'color',
//       },
//       '6': {
//         value: '#535c6e',
//         type: 'color',
//       },
//       '7': {
//         value: '#3a4558',
//         type: 'color',
//       },
//       '8': {
//         value: '#2b3546',
//         type: 'color',
//       },
//       '9': {
//         value: '#1B202B',
//         type: 'color',
//       },
//       '10': {
//         value: '#0E1116',
//         type: 'color',
//       },
//     },
//   },
//   'light/map': {
//     sizeXXL: {
//       value: '$sizeUnit * ($sizeStep + 8)',
//       type: 'sizing',
//     },
//     sizeXL: {
//       value: '$sizeUnit * ($sizeStep + 4)',
//       type: 'sizing',
//     },
//     sizeLG: {
//       value: '$sizeUnit * ($sizeStep + 2)',
//       type: 'sizing',
//     },
//     sizeMD: {
//       value: '$sizeUnit * ($sizeStep + 1)',
//       type: 'sizing',
//     },
//     sizeMS: {
//       value: '$sizeUnit * $sizeStep',
//       type: 'sizing',
//     },
//     size: {
//       value: '$sizeUnit * $sizeStep',
//       type: 'sizing',
//     },
//     sizeSM: {
//       value: '$sizeUnit * ($sizeStep - 1)',
//       type: 'sizing',
//     },
//     sizeXS: {
//       value: '$sizeUnit * ($sizeStep - 2)',
//       type: 'sizing',
//     },
//     sizeXXS: {
//       value: '$sizeUnit * ($sizeStep - 3)',
//       type: 'sizing',
//     },
//     controlHeightSM: {
//       value: '$controlHeight * 0.75',
//       type: 'sizing',
//     },
//     controlHeightXS: {
//       value: '$controlHeight * 0.5',
//       type: 'sizing',
//     },
//     controlHeightLG: {
//       value: '$controlHeight * 1.25',
//       type: 'sizing',
//     },
//     borderRadiusXS: {
//       value: '2',
//       type: 'borderRadius',
//     },
//     borderRadiusSM: {
//       value: '4',
//       type: 'borderRadius',
//     },
//     borderRadiusLG: {
//       value: '6',
//       type: 'borderRadius',
//     },
//     borderRadiusOuter: {
//       value: '4',
//       type: 'borderRadius',
//     },
//     colorText: {
//       value: '{colorTextBase}',
//       type: 'color',
//     },
//     colorTextSecondary: {
//       value: 'rgba({colorTextBase}, 0.65)',
//       type: 'color',
//     },
//     colorTextTertiary: {
//       value: 'rgba({colorTextBase}, 0.45)',
//       type: 'color',
//     },
//     colorTextQuaternary: {
//       value: 'rgba({colorTextBase}, 0.25)',
//       type: 'color',
//     },
//     colorFill: {
//       value: 'rgba({colorTextBase}, 0.15)',
//       type: 'color',
//     },
//     colorFillSecondary: {
//       value: 'rgba({colorTextBase}, 0.06)',
//       type: 'color',
//     },
//     colorFillTertiary: {
//       value: 'rgba({colorTextBase}, 0.04)',
//       type: 'color',
//     },
//     colorBgContainer: {
//       value: 'rgba({colorPrimaryBase}, 0.05)',
//       type: 'color',
//     },
//     colorFillQuaternary: {
//       value: 'rgba({colorTextBase}, 0.02)',
//       type: 'color',
//     },
//     colorBgLayout: {
//       value: '{neutral.1}',
//       type: 'color',
//     },
//     colorBgElevated: {
//       value: '{colorBgBase}',
//       type: 'color',
//     },
//     colorBgSpotlight: {
//       value: 'rgba({colorTextBase}, 0.85)',
//       type: 'color',
//     },
//     colorBorder: {
//       value: 'rgba({colorTextBase}, 0.15)',
//       type: 'color',
//     },
//     colorBorderSecondary: {
//       value: 'rgba({colorTextBase}, 0.06)',
//       type: 'color',
//     },
//     colorSplit: {
//       value: 'rgba({colorTextBase}, 0.06)',
//       type: 'color',
//     },
//     colorPrimaryBg: {
//       value: '{neutral.1}',
//       type: 'color',
//     },
//     colorPrimaryBgHover: {
//       value: '{neutral.2}',
//       type: 'color',
//     },
//     colorPrimaryBorder: {
//       value: '{neutral.3}',
//       type: 'color',
//     },
//     colorPrimaryBorderHover: {
//       value: '{neutral.4}',
//       type: 'color',
//     },
//     colorPrimaryBase: {
//       value: '{neutral.5}',
//       type: 'color',
//     },
//     colorPrimaryHover: {
//       value: '{neutral.6}',
//       type: 'color',
//     },
//     colorPrimaryActive: {
//       value: '{neutral.7}',
//       type: 'color',
//     },
//     colorPrimaryTextHover: {
//       value: '{neutral.8}',
//       type: 'color',
//     },
//     colorPrimaryText: {
//       value: '{neutral.9}',
//       type: 'color',
//     },
//     colorPrimaryTextActive: {
//       value: '{neutral.10}',
//       type: 'color',
//     },
//     colorSuccessBg: {
//       value: '{lime.1}',
//       type: 'color',
//     },
//     colorSuccessBgHover: {
//       value: '{lime.2}',
//       type: 'color',
//     },
//     colorSuccessBorder: {
//       value: '{lime.3}',
//       type: 'color',
//     },
//     colorSuccessBorderHover: {
//       value: '{lime.4}',
//       type: 'color',
//     },
//     colorSuccessHover: {
//       value: '{lime.5}',
//       type: 'color',
//     },
//     colorSuccessBase: {
//       value: '{lime.6}',
//       type: 'color',
//     },
//     colorSuccessActive: {
//       value: '{lime.7}',
//       type: 'color',
//     },
//     colorSuccessTextHover: {
//       value: '{lime.8}',
//       type: 'color',
//     },
//     colorSuccessText: {
//       value: '{lime.9}',
//       type: 'color',
//     },
//     colorSuccessTextActive: {
//       value: '{lime.10}',
//       type: 'color',
//     },
//     colorWarningBg: {
//       value: '{gold.1}',
//       type: 'color',
//     },
//     colorWarningBgHover: {
//       value: '{gold.2}',
//       type: 'color',
//     },
//     colorWarningBorder: {
//       value: '{gold.3}',
//       type: 'color',
//     },
//     colorWarningBorderHover: {
//       value: '{gold.4}',
//       type: 'color',
//     },
//     colorWarningHover: {
//       value: '{gold.5}',
//       type: 'color',
//     },
//     colorWarningBase: {
//       value: '{gold.6}',
//       type: 'color',
//     },
//     colorWarningActive: {
//       value: '{gold.7}',
//       type: 'color',
//     },
//     colorWarningTextHover: {
//       value: '{gold.8}',
//       type: 'color',
//     },
//     colorWarningText: {
//       value: '{gold.9}',
//       type: 'color',
//     },
//     colorWarningTextActive: {
//       value: '{gold.10}',
//       type: 'color',
//     },
//     colorErrorBg: {
//       value: '{red.1}',
//       type: 'color',
//     },
//     colorErrorBgHover: {
//       value: '{red.2}',
//       type: 'color',
//     },
//     colorErrorBorder: {
//       value: '{red.3}',
//       type: 'color',
//     },
//     colorErrorBorderHover: {
//       value: '{red.4}',
//       type: 'color',
//     },
//     colorErrorHover: {
//       value: '{red.5}',
//       type: 'color',
//     },
//     colorErrorBase: {
//       value: '{red.6}',
//       type: 'color',
//     },
//     colorErrorActive: {
//       value: '{red.7}',
//       type: 'color',
//     },
//     colorErrorTextHover: {
//       value: '{red.8}',
//       type: 'color',
//     },
//     colorErrorText: {
//       value: '{red.9}',
//       type: 'color',
//     },
//     colorErrorTextActive: {
//       value: '{red.10}',
//       type: 'color',
//     },
//     colorInfoBg: {
//       value: '{neutral.1}',
//       type: 'color',
//     },
//     colorInfoBgHover: {
//       value: '{neutral.2}',
//       type: 'color',
//     },
//     colorInfoBorder: {
//       value: '{neutral.3}',
//       type: 'color',
//     },
//     colorInfoBorderHover: {
//       value: '{neutral.4}',
//       type: 'color',
//     },
//     colorInfoHover: {
//       value: '{neutral.5}',
//       type: 'color',
//     },
//     colorInfoBase: {
//       value: '{neutral.6}',
//       type: 'color',
//     },
//     colorInfoActive: {
//       value: '{neutral.7}',
//       type: 'color',
//     },
//     colorInfoTextHover: {
//       value: '{neutral.8}',
//       type: 'color',
//     },
//     colorInfoText: {
//       value: '{neutral.9}',
//       type: 'color',
//     },
//     colorInfoTextActive: {
//       value: '{neutral.10}',
//       type: 'color',
//     },
//     colorBgMask: {
//       value: 'rgba(0, 0, 0, 0.45)',
//       type: 'color',
//     },
//     stepsDotSize: {
//       value: '{controlHeight} / 4',
//       type: 'sizing',
//     },
//     stepsCurrentDotSize: {
//       value: '{controlHeightLG} / 4',
//       type: 'sizing',
//     },
//     rateStarSize: {
//       value: '{controlHeightLG} * 0.5',
//       type: 'sizing',
//     },
//     borderRadiusXL: {
//       value: '9999',
//       type: 'borderRadius',
//     },
//     colorProductBg: {
//       value: '{green.1}',
//       type: 'color',
//     },
//     colorProductBgHover: {
//       value: '{green.2}',
//       type: 'color',
//     },
//     colorProductBorder: {
//       value: '{green.3}',
//       type: 'color',
//     },
//     colorProductBorderHover: {
//       value: '{green.4}',
//       type: 'color',
//     },
//     colorProductHover: {
//       value: '{green.5}',
//       type: 'color',
//     },
//     colorProductBase: {
//       value: '{green.6}',
//       type: 'color',
//     },
//     colorProductActive: {
//       value: '{green.7}',
//       type: 'color',
//     },
//     colorProductTextHover: {
//       value: '{green.8}',
//       type: 'color',
//     },
//     colorProductText: {
//       value: '{green.9}',
//       type: 'color',
//     },
//     colorProductTextActive: {
//       value: '{green.10}',
//       type: 'color',
//     },
//   },
//   'light/seed': {
//     colorTextBase: {
//       value: '{neutral.9}',
//       type: 'color',
//     },
//     colorTextLightSolid: {
//       value: '{neutral.1}',
//       type: 'color',
//     },
//     colorBgBase: {
//       value: '{neutral.1}',
//       type: 'color',
//     },
//     fontFamily: {
//       value: 'Matter',
//       type: 'fontFamilies',
//       description:
//         '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";\n',
//     },
//     fontSize: {
//       value: '14',
//       type: 'fontSizes',
//     },
//     lineWidth: {
//       value: '1',
//       type: 'borderWidth',
//     },
//     borderRadius: {
//       value: '4',
//       type: 'borderRadius',
//     },
//     sizeUnit: {
//       value: '4',
//       type: 'sizing',
//     },
//     sizePopupArrow: {
//       value: '16',
//       type: 'sizing',
//     },
//     controlHeight: {
//       value: '32',
//       type: 'sizing',
//     },
//     opacityImage: {
//       value: '1',
//       type: 'opacity',
//     },
//     sizeStep: {
//       value: '4',
//       type: 'sizing',
//     },
//     colorPrimary: {
//       value: '{colorPrimaryBase}',
//       type: 'color',
//     },
//     colorError: {
//       value: '{colorErrorBase}',
//       type: 'color',
//     },
//     colorWarning: {
//       value: '{colorWarningBase}',
//       type: 'color',
//     },
//     colorSuccess: {
//       value: '{colorSuccessBase}',
//       type: 'color',
//     },
//     colorInfo: {
//       value: '{colorInfoBase}',
//       type: 'color',
//     },
//     colorProduct: {
//       value: '{green.6}',
//       type: 'color',
//       description: 'base product color',
//     },
//   },
//   'light/alias': {
//     colorFillContent: {
//       value: '{colorFillSecondary}',
//       type: 'color',
//     },
//     colorFillContentHover: {
//       value: '{colorFill}',
//       type: 'color',
//     },
//     colorFillAlter: {
//       value: '{colorFillQuaternary}',
//       type: 'color',
//     },
//     colorBgContainerDisabled: {
//       value: '{colorFillTertiary}',
//       type: 'color',
//     },
//     colorBorderBg: {
//       value: '{colorBgContainer}',
//       type: 'color',
//     },
//     colorTextPlaceholder: {
//       value: '{colorTextQuaternary}',
//       type: 'color',
//     },
//     colorTextDisabled: {
//       value: '{colorTextQuaternary}',
//       type: 'color',
//     },
//     colorTextHeading: {
//       value: '{colorText}',
//       type: 'color',
//     },
//     colorTextLabel: {
//       value: '{colorTextSecondary}',
//       type: 'color',
//     },
//     colorTextDescription: {
//       value: '{colorTextTertiary}',
//       type: 'color',
//     },
//     colorBgTextHover: {
//       value: '{colorFillSecondary}',
//       type: 'color',
//     },
//     colorBgTextActive: {
//       value: '{colorFill}',
//       type: 'color',
//     },
//     colorIcon: {
//       value: '{colorTextTertiary}',
//       type: 'color',
//     },
//     colorIconHover: {
//       value: '{colorText}',
//       type: 'color',
//     },
//     colorLink: {
//       value: '{colorPrimaryHover}',
//       type: 'color',
//     },
//     colorLinkHover: {
//       value: '{colorPrimaryBase}',
//       type: 'color',
//     },
//     colorLinkActive: {
//       value: '{colorPrimaryActive}',
//       type: 'color',
//     },
//     colorHighlight: {
//       value: '{colorErrorHover}',
//       type: 'color',
//     },
//     controlOutline: {
//       value: '{colorPrimaryBg}',
//       type: 'color',
//     },
//     colorWarningOutline: {
//       value: '{colorWarningBg}',
//       type: 'color',
//     },
//     colorErrorOutline: {
//       value: '{colorErrorBg}',
//       type: 'color',
//     },
//     fontSizeSM: {
//       value: '12',
//       type: 'fontSizes',
//     },
//     fontSizeLG: {
//       value: '16',
//       type: 'fontSizes',
//     },
//     fontSizeXL: {
//       value: '20',
//       type: 'fontSizes',
//     },
//     fontSizeIcon: {
//       value: '12',
//       type: 'sizing',
//     },
//     fontSizeHeading1: {
//       value: '38',
//       type: 'fontSizes',
//     },
//     fontSizeHeading2: {
//       value: '30',
//       type: 'fontSizes',
//     },
//     fontSizeHeading3: {
//       value: '24',
//       type: 'fontSizes',
//     },
//     fontSizeHeading4: {
//       value: '20',
//       type: 'fontSizes',
//     },
//     fontSizeHeading5: {
//       value: '16',
//       type: 'fontSizes',
//     },
//     fontWeightNormal: {
//       value: '400',
//       type: 'fontWeights',
//     },
//     fontWeightStrong: {
//       value: '600',
//       type: 'fontWeights',
//     },
//     controlLineWidth: {
//       value: '{lineWidth}',
//       type: 'borderWidth',
//     },
//     controlOutlineWidth: {
//       value: '{lineWidth} * 2',
//       type: 'borderWidth',
//     },
//     controlItemBgHover: {
//       value: '{colorFillTertiary}',
//       type: 'color',
//     },
//     controlItemBgActive: {
//       value: '{colorPrimaryBg}',
//       type: 'color',
//     },
//     controlItemBgActiveHover: {
//       value: '{colorPrimaryBgHover}',
//       type: 'color',
//     },
//     controlInteractiveSize: {
//       value: '{controlHeight} / 2',
//       type: 'sizing',
//     },
//     controlItemBgActiveDisabled: {
//       value: '{colorFill}',
//       type: 'color',
//     },
//     controlTmpOutline: {
//       value: '{colorFillQuaternary}',
//       type: 'color',
//     },
//     opacityLoading: {
//       value: '0.65',
//       type: 'opacity',
//     },
//     padding: {
//       value: '{size}',
//       type: 'spacing',
//     },
//     paddingMD: {
//       value: '{sizeMD}',
//       type: 'spacing',
//     },
//     paddingSM: {
//       value: '{sizeSM}',
//       type: 'spacing',
//     },
//     paddingXS: {
//       value: '{sizeXS}',
//       type: 'spacing',
//     },
//     paddingXXS: {
//       value: '{sizeXXS}',
//       type: 'spacing',
//     },
//     paddingLG: {
//       value: '{sizeLG}',
//       type: 'spacing',
//     },
//     paddingXL: {
//       value: '{sizeXL}',
//       type: 'spacing',
//     },
//     paddingXXL: {
//       value: '{sizeXXL}',
//       type: 'spacing',
//     },
//     margin: {
//       value: '{size}',
//       type: 'spacing',
//     },
//     marginSM: {
//       value: '{sizeSM}',
//       type: 'spacing',
//     },
//     marginXS: {
//       value: '{sizeXS}',
//       type: 'spacing',
//     },
//     marginXXS: {
//       value: '{sizeXXS}',
//       type: 'spacing',
//     },
//     marginLG: {
//       value: '{sizeLG}',
//       type: 'spacing',
//     },
//     marginXL: {
//       value: '{sizeXL}',
//       type: 'spacing',
//     },
//     marginXXL: {
//       value: '{sizeXXL}',
//       type: 'spacing',
//     },
//     controlPaddingHorizontal: {
//       value: '12',
//       type: 'spacing',
//     },
//     controlPaddingHorizontalSM: {
//       value: '8',
//       type: 'spacing',
//     },
//     Base: {
//       'Base Normal': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSize} + 8',
//           fontSize: '{fontSize}',
//         },
//         type: 'typography',
//       },
//       'Base Strong': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSize} + 8',
//           fontSize: '{fontSize}',
//         },
//         type: 'typography',
//       },
//       'Base Underline': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSize} + 8',
//           fontSize: '{fontSize}',
//           textDecoration: 'underline',
//         },
//         type: 'typography',
//       },
//       'Base Delete': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSize} + 8',
//           fontSize: '{fontSize}',
//           textDecoration: 'line-through',
//         },
//         type: 'typography',
//       },
//       'Base Italic': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightItalic}',
//           lineHeight: '{fontSize} + 8',
//           fontSize: '{fontSize}',
//           textDecoration: '',
//         },
//         type: 'typography',
//       },
//     },
//     SM: {
//       'SM Normal': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeSM} + 8',
//           fontSize: '{fontSizeSM}',
//         },
//         type: 'typography',
//       },
//       'SM Strong': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeSM} + 8',
//           fontSize: '{fontSizeSM}',
//         },
//         type: 'typography',
//       },
//       'SM Underline': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeSM} + 8',
//           fontSize: '{fontSizeSM}',
//           textDecoration: 'underline',
//         },
//         type: 'typography',
//       },
//       'SM Delete': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeSM} + 8',
//           fontSize: '{fontSizeSM}',
//           textDecoration: 'line-through',
//         },
//         type: 'typography',
//       },
//       'SM Italic': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightItalic}',
//           lineHeight: '{fontSizeSM} + 8',
//           fontSize: '{fontSizeSM}',
//           textDecoration: 'line-through',
//         },
//         type: 'typography',
//       },
//     },
//     LG: {
//       'LG Normal': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeLG} + 8',
//           fontSize: '{fontSizeLG}',
//         },
//         type: 'typography',
//       },
//       'LG Strong': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeLG} + 8',
//           fontSize: '{fontSizeLG}',
//         },
//         type: 'typography',
//       },
//       'LG Underline': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeLG} + 8',
//           fontSize: '{fontSizeLG}',
//           textDecoration: 'underline',
//         },
//         type: 'typography',
//       },
//       'LG Delete': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeLG} + 8',
//           fontSize: '{fontSizeLG}',
//           textDecoration: 'line-through',
//         },
//         type: 'typography',
//       },
//       'LG Italic': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightItalic}',
//           lineHeight: '{fontSizeLG} + 8',
//           fontSize: '{fontSizeLG}',
//         },
//         type: 'typography',
//       },
//     },
//     XL: {
//       'XL Normal': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeXL} + 8',
//           fontSize: '{fontSizeXL}',
//         },
//         type: 'typography',
//       },
//       'XL Strong': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeXL} + 8',
//           fontSize: '{fontSizeXL}',
//         },
//         type: 'typography',
//       },
//       'XL Underline': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeXL} + 8',
//           fontSize: '{fontSizeXL}',
//           textDecoration: 'underline',
//         },
//         type: 'typography',
//       },
//       'XL Delete': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightNormal}',
//           lineHeight: '{fontSizeXL} + 8',
//           fontSize: '{fontSizeXL}',
//           textDecoration: 'line-through',
//         },
//         type: 'typography',
//       },
//       'XL Italic': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightItalic}',
//           lineHeight: '{fontSizeXL} + 8',
//           fontSize: '{fontSizeXL}',
//         },
//         type: 'typography',
//       },
//     },
//     Heading: {
//       'Heading 1': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeHeading1} + 8',
//           fontSize: '{fontSizeHeading1}',
//         },
//         type: 'typography',
//       },
//       'Heading 2': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeHeading2} + 8',
//           fontSize: '{fontSizeHeading2}',
//         },
//         type: 'typography',
//       },
//       'Heading 3': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeHeading3} + 8',
//           fontSize: '{fontSizeHeading3}',
//         },
//         type: 'typography',
//       },
//       'Heading 4': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeHeading4} + 8',
//           fontSize: '{fontSizeHeading4}',
//         },
//         type: 'typography',
//       },
//       'Heading 5': {
//         value: {
//           fontFamily: '{fontFamily}',
//           fontWeight: '{fontWeightStrong}',
//           lineHeight: '{fontSizeHeading5} + 8',
//           fontSize: '{fontSizeHeading5}',
//         },
//         type: 'typography',
//       },
//     },
//     fontWeightItalic: {
//       value: 'Regular Italic',
//       type: 'fontWeights',
//     },
//     paddingContentHorizontalLG: {
//       value: '{sizeLG}',
//       type: 'spacing',
//     },
//     paddingContentVerticalLG: {
//       value: '{sizeMS}',
//       type: 'spacing',
//     },
//     paddingContentHorizontal: {
//       value: '{sizeMS}',
//       type: 'spacing',
//     },
//     paddingContentVertical: {
//       value: '{sizeSM}',
//       type: 'spacing',
//     },
//     paddingContentHorizontalSM: {
//       value: '{size}',
//       type: 'spacing',
//     },
//     paddingContentVerticalSM: {
//       value: '{sizeXS}',
//       type: 'spacing',
//     },
//     boxShadow: {
//       value: [
//         {
//           x: '0',
//           y: '1',
//           blur: '2',
//           spread: '0',
//           color: 'rgba(0,0,0,0.03)',
//           type: 'dropShadow',
//         },
//         {
//           x: '0',
//           y: '1',
//           blur: '6',
//           spread: '-1',
//           color: 'rgba(0,0,0,0.02)',
//           type: 'dropShadow',
//         },
//         {
//           x: '0',
//           y: '2',
//           blur: '4',
//           spread: '0',
//           color: 'rgba(0,0,0,0.02)',
//           type: 'dropShadow',
//         },
//       ],
//       type: 'boxShadow',
//     },
//     boxShadowSecondary: {
//       value: [
//         {
//           x: '0',
//           y: '6',
//           blur: '16',
//           spread: '0',
//           color: 'rgba(0,0,0,0.08)',
//           type: 'dropShadow',
//         },
//         {
//           x: '0',
//           y: '3',
//           blur: '6',
//           spread: '-4',
//           color: 'rgba(0,0,0,0.12)',
//           type: 'dropShadow',
//         },
//         {
//           x: '0',
//           y: '9',
//           blur: '28',
//           spread: '8',
//           color: 'rgba(0,0,0,0.05)',
//           type: 'dropShadow',
//         },
//       ],
//       type: 'boxShadow',
//     },
//     boxShadowCard: {
//       value: [
//         {
//           x: '0',
//           y: '1',
//           blur: '2',
//           spread: '-2',
//           color: 'rgba(0,0,0,0.16)',
//           type: 'dropShadow',
//         },
//         {
//           x: '0',
//           y: '3',
//           blur: '6',
//           spread: '0',
//           color: 'rgba(0,0,0,0.12)',
//           type: 'dropShadow',
//         },
//         {
//           x: '0',
//           y: '5',
//           blur: '12',
//           spread: '4',
//           color: 'rgba(0,0,0,0.09)',
//           type: 'dropShadow',
//         },
//       ],
//       type: 'boxShadow',
//     },
//     colorItemText: {
//       value: 'rgba({colorTextLightSolid}, 0.65)',
//       type: 'color',
//     },
//     colorItemTextDisabled: {
//       value: 'rgba({colorTextLightSolid}, 0.25)',
//       type: 'color',
//     },
//     focusPrimary: {
//       value: {
//         x: '0',
//         y: '0',
//         blur: '0',
//         spread: '2',
//         color: '{colorPrimaryBg}',
//         type: 'dropShadow',
//       },
//       type: 'boxShadow',
//     },
//     focusError: {
//       value: {
//         x: '0',
//         y: '0',
//         blur: '0',
//         spread: '2',
//         color: '{colorErrorBg}',
//         type: 'dropShadow',
//       },
//       type: 'boxShadow',
//     },
//     focusWarning: {
//       value: {
//         x: '0',
//         y: '0',
//         blur: '0',
//         spread: '2',
//         color: '{colorWarningBg}',
//         type: 'dropShadow',
//       },
//       type: 'boxShadow',
//     },
//     tooltipBg: {
//       value: '{colorBgContainer}',
//       type: 'color',
//     },
//     segmentedBgColorSelected: {
//       value: '#ffffff',
//       type: 'color',
//     },
//     segmentedGroupBg: {
//       value: '{colorFillTertiary}',
//       type: 'color',
//     },
//     colorMenuDarkBg: {
//       value: '#001529',
//       type: 'color',
//     },
//     colorMenuSubItemBg: {
//       value: '#000c17',
//       type: 'color',
//     },
//     boxShadowButtonSolid: {
//       value: {
//         x: '0',
//         y: '{controlOutlineWidth}',
//         blur: '0',
//         spread: '0',
//         color: '{controlTmpOutline}',
//         type: 'dropShadow',
//       },
//       type: 'boxShadow',
//     },
//     paddingTooltipContentVertical: {
//       value: '({paddingSM} / 2)',
//       type: 'spacing',
//     },
//   },
//   $themes: [],
//   $metadata: {
//     tokenSetOrder: ['light/colors', 'light/map', 'light/seed', 'light/alias'],
//   },
// };

export const THEME = {
  token: {
    colorPrimary: '#77A63A',
    colorPrimaryBg: '#F8FCF3',
    colorPrimaryBgHover: '#DEEEC9',
    colorPrimaryBorder: '#C1DD9D',
    colorPrimaryBorderHover: '#ADCD83',
    colorPrimaryHover: '#8FBE50',
    colorPrimaryBase: '#77A63A',
    colorPrimaryActive: '#5D8627',
    colorPrimaryTextHover: '#426218',
    colorPrimaryText: '#2C4110',
    colorPrimaryTextActive: '#162207',
    colorLink: '#C1C6D1',
    colorLinkHover: '#1B202B',
    colorLinkActive: '#1B202B',
    colorIconHover: '#1B202B',
  },
  components: {
    Table: {
      fontFamily: 'Matter',
      lineHeight: 1.5,
      size: 14,
    },
    Button: {
      colorBgContainer: '#6C74860D',
      colorBorder: '#1B202B26',
      colorText: '#1B202B',
      colorTextTertiary: '#1B202B73',
    },
    Typography: {
      colorText: '#1B202B',
    },
    Pagination: {
      colorPrimary: '#6C7486',
      colorText: '#1B202B',
      colorPrimaryHover: '#3B424F',
      colorTextDisabled: '#1B202B40',
      colorBorder: '#6C7486',
      borderRadius: 9999,
    },
    Tree: {
      colorBorder: '#1B202B26',
      colorText: '#1B202B',
      controlItemBgActiveHover: '#E8E9EE',
      nodeSelectedBg: '#E8E9EE',
    },
  },
} as ThemeConfig;
