import { ConsoleLogProvider } from './loggingProviders/ConsoleLogProvider';
import { SentryLogProvider } from './loggingProviders/SentryLogProvider';

export enum LogAreaType {
  ArchitectureAndSettings = 'ArchitectureAndSettings',
  Service = 'Service',
  Http = 'Http',
  Component = 'Component',
}

export interface LogProvider {
  logArea: (
    area: string,
    areaType: LogAreaType,
    areaCallback: (loggedArea: string) => void,
  ) => void;
  log: (message: string, context?: Record<string, any>) => void;
  logError: (message: string | Error, context?: Record<string, any>) => void;
}

const ENABLE_SENTRY = !!import.meta.env.VITE_ENABLE_SENTRY;

class Logger {
  private logProviders: Array<LogProvider>;

  constructor() {
    this.logProviders = [];
    this.logProviders.push(new ConsoleLogProvider());
    if (ENABLE_SENTRY) {
      this.logProviders.push(new SentryLogProvider());
    }
  }

  logGroup = (
    area: string,
    areaLevel: LogAreaType,
    areaCallback: (area: string) => void,
  ) => {
    this.logProviders.map((logProvider) => {
      logProvider.logArea(area, areaLevel, areaCallback);
    });
  };

  log = (message: string, context?: Record<string, any>) => {
    this.logProviders.map((logProvider) => {
      logProvider.log(message, context);
    });
  };

  logError = (
    error: string | Error,
    context?: Record<string, any> | undefined,
  ) => {
    this.logProviders.map((logProvider) => {
      logProvider.logError(error, context);
    });
  };
}

const logger = new Logger();
export default logger;
