import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from '~/core/hooks/const';
import dayjs from '~/core/lib/dayjs';
import { createInd } from '~/core/services/IndService';

/**
 * @deprecated - The pattern of individual hooks per query/mutation predates the Weave FE team.
 * THis approach has been deprecated in favor of "feature"-based hooks (e.g. useRefinement, useOrganization) that
 * combine related queries and mutations as needed.  Please avoid this usage in favor of using the query directly
 * in a feature hook.  This hook will be retired in the future.
 */
export const useCreateIndDocument = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: Partial<IndApplication>) => {
      request.targetStartDate =
        request.targetStartDate ?
          dayjs(request.targetStartDate).format('YYYY-MM-DD')
        : '';
      request.submissionDateGoal =
        request.submissionDateGoal ?
          dayjs(request.submissionDateGoal).format('YYYY-MM-DD')
        : '';
      return createInd(request);
    },
    onSuccess: async () => {
      await queryClient.cancelQueries({
        queryKey: QueryKeys.GET_IND_APPLICATIONS(),
      });
      await queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_IND_APPLICATIONS(),
      });
      await queryClient.invalidateQueries({
        queryKey: QueryKeys.GET_ORG_STATS,
      });
    },
  });
};
