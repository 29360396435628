import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

import { cn } from '~/core/lib/tailwind-util';

import { BodyText } from '../../atoms/Text/Body';
import { Weave2024Button } from '../../atoms/Weave2024Button';
import { WeaveIcon } from '../../atoms/WeaveIcon';

export type BannerType = 'neutral' | 'success' | 'warning' | 'error';

interface BannerProps {
  bannerType: BannerType;
  message: string;
  action?: {
    text: string;
    onAction: () => void;
  };
  hasCloseButton?: boolean;
  onClickClose: () => void;
  className?: string;
  rounded?: boolean;
  isLoadingBehavior?: boolean;
}

const SingleLineHeight = 32;

interface BannerVariant {
  icon: React.ReactNode;
  background: string;
  borderColor: string;
  textColor: string;
  actionStyles: string;
  loadingColor: string;
}

const BannerVariantMap = new Map<BannerType, BannerVariant>([
  [
    'neutral',
    {
      icon: <WeaveIcon.InfoCircleFilled className="text-blue-5" size="lg" />,
      background: 'bg-blue-1',
      borderColor: 'border-blue-2',
      textColor: 'text-neutral-9',
      actionStyles:
        'hover:border-blue-1 focus:border-blue-2 active:border-blue-2',
      loadingColor: '#171717',
    },
  ],
  [
    'success',
    {
      icon: <WeaveIcon.CheckCircleFilled className="text-lime-7" size="lg" />,
      background: 'bg-lime-2',
      borderColor: 'border-lime-6',
      textColor: 'text-lime-9',
      actionStyles:
        'hover:border-lime-2 focus:border-lime-2 active:border-lime-2',
      loadingColor: '#365314',
    },
  ],
  [
    'warning',
    {
      icon: <WeaveIcon.WarningFilled className="text-gold-6" size="lg" />,
      background: 'bg-gold-1',
      borderColor: 'border-gold-3',
      textColor: 'text-gold-9',
      actionStyles:
        'hover:border-gold-5 focus:border-gold-5 active:border-gold-5',
      loadingColor: '#7835of',
    },
  ],
  [
    'error',
    {
      icon: <WeaveIcon.CloseCircleFilled className="text-red-6" size="lg" />,
      background: 'bg-red-1',
      borderColor: 'border-red-3',
      textColor: 'text-red-9',
      actionStyles:
        'border-red-6 focus:border-red-5 focus:text-red-5 active:border-red-7 active:text-red-7',
      loadingColor: '#7f1d1d',
    },
  ],
]);

export const Banner = ({
  bannerType,
  message,
  action,
  onClickClose,
  hasCloseButton = true,
  rounded = true,
  className,
  isLoadingBehavior = false,
}: BannerProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [borderRadius, setBorderRadius] = useState(
    rounded ? 'rounded-full' : 'rounded-none',
  );
  const [itemAlignment, setItemAlignment] = useState('');

  useEffect(() => {
    if (height > SingleLineHeight) {
      if (rounded) setBorderRadius('rounded-md');
      else setBorderRadius('rounded-none');
      setItemAlignment('items-start');
    } else {
      if (rounded) setBorderRadius('rounded-full');
      else setBorderRadius('rounded-none');
      setItemAlignment('items-center');
    }
  }, [height, rounded]);

  return (
    <div
      className={cn(
        'w-full border-[1px] border-solid flex flex-nowrap px-5 py-3',
        BannerVariantMap.get(bannerType)?.background,
        BannerVariantMap.get(bannerType)?.borderColor,
        BannerVariantMap.get(bannerType)?.textColor,
        borderRadius,
        itemAlignment,
        className,
      )}
      ref={ref}
    >
      {isLoadingBehavior ?
        <></>
      : <div>{BannerVariantMap.get(bannerType)?.icon}</div>}
      <div className="flex-1 mx-5">
        <BodyText
          size="body3"
          className={BannerVariantMap.get(bannerType)?.textColor}
        >
          {message}
        </BodyText>
      </div>
      {isLoadingBehavior ?
        <div
          className={cn(BannerVariantMap.get(bannerType)?.textColor, 'text-sm')}
        >
          <Spin
            indicator={
              <LoadingOutlined
                className="text-sm"
                style={{
                  color: BannerVariantMap.get(bannerType)?.loadingColor,
                }}
              />
            }
            size="default"
          />
        </div>
      : <></>}
      {action ?
        <div className="mr-5">
          <Weave2024Button
            ghost
            className={cn(
              BannerVariantMap.get(bannerType)?.borderColor,
              BannerVariantMap.get(bannerType)?.textColor,
              BannerVariantMap.get(bannerType)?.actionStyles,
            )}
            onClick={action.onAction}
          >
            {action.text}
          </Weave2024Button>
        </div>
      : <></>}
      {hasCloseButton && (
        <div>
          <WeaveIcon.CloseOutlined
            size="lg"
            onClick={onClickClose}
            className="text-weave-gray-lighter"
          />
        </div>
      )}
    </div>
  );
};
