import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import {
  ComponentType,
  forwardRef,
  ForwardRefExoticComponent,
  SVGProps,
} from 'react';

import { cn } from '~/core/lib/tailwind-util';
import { SupportedIconColor } from '~/types/tokens/supported-icon-color';
import { weaveColors } from '~/types/tokens/weave-colors';

import { AntIconInterface, AntIconInterfaceProps } from './ant-icon-interface';

export type IconProps = Omit<AntIconInterfaceProps, 'size'> & {
  // @j-weave: menu, md, and lg should get readjusted to md, lg, and xl respectively,
  // but this should be done in a separate PR.  This is present to preserve backwards compatibility.
  size?: 'xs' | 'sm' | 'menu' | 'md' | 'lg';
  color?: SupportedIconColor | 'inherit';
};

// Icon size definitions are a bit different than the usual text.
const sizeMap: Record<'xs' | 'sm' | 'menu' | 'md' | 'lg', string> = {
  xs: 'text-sm',
  sm: 'text-base',
  // @j-weave: menu, md, and lg should get readjusted to md, lg, and xl respectively,
  // but this should be done in a separate PR.  This is present to preserve backwards compatibility.
  menu: 'text-lg',
  md: 'text-xl',
  lg: 'text-2xl',
};

export const createAntIcon = (IconSource: AntIconInterface) => {
  const Icon = (props: IconProps) => {
    const { size, color, ...remainingProps } = props;

    const iconSize = size || 'sm';
    const iconColor = color || 'inherit';

    return (
      <IconSource
        {...remainingProps}
        twoToneColor={
          iconColor !== 'inherit' ? weaveColors[iconColor] : undefined
        }
        className={cn(
          sizeMap[iconSize],
          props.onClick ? 'cursor-pointer' : null,
          props.className,
          iconColor !== 'inherit' ? `text-${iconColor}` : '',
          // j-weave: At time of writing all of the styles in tailwind are marked with !important,
          // which means to override behavior we have to do this
          '!leading-[0]',
        )}
        style={iconColor !== 'inherit' ? { color } : {}}
      />
    );
  };
  return Icon;
};

export const createCustomIcon = ({
  imageSource,
}: {
  imageSource:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>
    | undefined;
}) => {
  const CustomIcon = forwardRef(
    (props: IconProps, ref: React.ForwardedRef<any>) => {
      const { size, color, ...remainingProps } = props;

      const iconSize = size || 'sm';
      const iconColor = color || 'inherit';

      return (
        <Icon
          component={imageSource}
          ref={ref}
          {...remainingProps}
          className={cn(
            sizeMap[iconSize],
            props.onClick ? 'cursor-pointer' : null,
            iconColor !== 'inherit' ? `text-${iconColor}` : '',
            props.className,
            // j-weave: At time of writing all of the styles in tailwind are marked with !important,
            // which means to override behavior we have to do this
            '!leading-[0]',
          )}
        />
      );
    },
  );
  return CustomIcon;
};
