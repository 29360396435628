import { Key, useEffect, useState } from 'react';

import { useSessionStore } from '~/core/store/sessions-store';
import { IndTreeFilters } from '~/pages/private/IndManagerPage/types';

import IndManagerContext from '.';
import { initialFiltersState } from '.';

export type IndManagerFilterState = {
  expandedKeys?: Key[];
  filters?: IndTreeFilters;
};

export const IndManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sessionStore = useSessionStore();
  const indManagerState = sessionStore.session?.indManagerState;

  const [expandedKeys, setExpandedKeys] = useState<Key[]>(
    indManagerState?.expandedKeys || [],
  );
  const [filters, setFilters] = useState<IndTreeFilters>(
    indManagerState?.filters || initialFiltersState,
  );

  useEffect(() => {
    if (sessionStore.session === null) return;
    sessionStore.setSession({
      ...sessionStore.session,
      indManagerState: {
        filters,
        expandedKeys,
      },
    });
  }, [filters, expandedKeys]);

  return (
    <IndManagerContext.Provider
      value={{
        expandedKeys,
        setExpandedKeys,
        filters,
        setFilters,
        resetFilters: () => setFilters(initialFiltersState),
      }}
    >
      {children}
    </IndManagerContext.Provider>
  );
};
