import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface SidebarPanelsStore {
  isReferencesPanelOpen: boolean;
  isRefinementPanelOpen: boolean;
  isReviewPanelOpen: boolean;
  isAutoUpdatePanelOpen: boolean;
  isFileViewerPanelOpen: boolean;
  isVersionHistoryPanelOpen: boolean;
  setIsReferencesPanelOpen: (isOpen: boolean) => void;
  setIsRefinementPanelOpen: (isOpen: boolean) => void;
  setIsReviewPanelOpen: (isOpen: boolean) => void;
  setIsAutoUpdatePanelOpen: (isOpen: boolean) => void;
  setIsFileViewerPanelOpen: (isOpen: boolean) => void;
  setIsVersionHistoryPanelOpen: (isOpen: boolean) => void;
  toggleRefinementPanelOpen: () => void;
  toggleReferencesPanelOpen: () => void;
  toggleReviewPanelOpen: () => void;
  toggleAutoUpdatePanelOpen: () => void;
  toggleFileViewerPanelOpen: () => void;
}

export const useSidebarPanelsStore = create<SidebarPanelsStore>()(
  devtools(
    (set) => ({
      isReferencesPanelOpen: false,
      isRefinementPanelOpen: false,
      isReviewPanelOpen: false,
      isAutoUpdatePanelOpen: false,
      isFileViewerPanelOpen: false,
      isVersionHistoryPanelOpen: false,
      setIsReferencesPanelOpen: (isOpen: boolean) =>
        set({ isReferencesPanelOpen: isOpen }),
      setIsRefinementPanelOpen: (isOpen: boolean) =>
        set({ isRefinementPanelOpen: isOpen }),
      setIsReviewPanelOpen: (isOpen: boolean) =>
        set({ isReviewPanelOpen: isOpen }),
      setIsAutoUpdatePanelOpen: (isOpen: boolean) =>
        set({ isAutoUpdatePanelOpen: isOpen }),
      setIsFileViewerPanelOpen: (isOpen: boolean) =>
        set({ isFileViewerPanelOpen: isOpen }),
      setIsVersionHistoryPanelOpen: (isOpen: boolean) =>
        set({ isVersionHistoryPanelOpen: isOpen }),
      toggleRefinementPanelOpen: () =>
        set((state) => ({
          isRefinementPanelOpen: !state.isRefinementPanelOpen,
        })),
      toggleReferencesPanelOpen: () =>
        set((state) => ({
          isReferencesPanelOpen: !state.isReferencesPanelOpen,
        })),
      toggleReviewPanelOpen: () =>
        set((state) => ({
          isReviewPanelOpen: !state.isReviewPanelOpen,
        })),
      toggleAutoUpdatePanelOpen: () =>
        set((state) => ({
          isAutoUpdatePanelOpen: !state.isAutoUpdatePanelOpen,
        })),
      toggleFileViewerPanelOpen: () =>
        set((state) => ({
          isFileViewerPanelOpen: !state.isFileViewerPanelOpen,
        })),
    }),
    {
      name: 'sidebar-panels-store',
    },
  ),
);

export const useIsReferencesPanelOpen = () =>
  useSidebarPanelsStore((state) => state.isReferencesPanelOpen);
export const useIsRefinementPanelOpen = () =>
  useSidebarPanelsStore((state) => state.isRefinementPanelOpen);
export const useIsReviewPanelOpen = () =>
  useSidebarPanelsStore((state) => state.isReviewPanelOpen);
export const useIsAutoUpdatePanelOpen = () =>
  useSidebarPanelsStore((state) => state.isAutoUpdatePanelOpen);
export const useIsFileViewerPanelOpen = () =>
  useSidebarPanelsStore((state) => state.isFileViewerPanelOpen);
export const useIsOpenVersionControlSlideOver = () =>
  useSidebarPanelsStore((state) => state.isVersionHistoryPanelOpen);
