import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as UserService from '~/core/services/UserService';

export const useSessionValidation = (onSessionInvalid: () => void) => {
  const intervalIdRef = useRef<NodeJS.Timeout | undefined>();
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback') ?? undefined;

  useEffect(() => {
    const validateSession = async () => {
      const authValid = await UserService.checkAuthValidity();
      if (!authValid) {
        if (intervalIdRef.current !== undefined) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = undefined;
        }
        localStorage.removeItem('sessionToken');
        onSessionInvalid();
      }
    };

    validateSession().catch((error) =>
      console.error('Initial session validation failed', error),
    );
    intervalIdRef.current = setInterval(validateSession, 15000);

    return () => {
      if (intervalIdRef.current !== undefined) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [callbackUrl, onSessionInvalid]);
};
