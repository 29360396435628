import { Tooltip } from 'antd';
import { ForwardedRef, useEffect, useRef, useState } from 'react';

import { cn } from '~/core/lib/tailwind-util';
import { TrackingId } from '~/features/tracking/const';

import {
  BaseTextProps,
  SupportedTextSizes,
  textColorMap,
  textFontWeightMap,
} from '../TextTypes';

export type SupportedTextElementTypes = 'div' | 'span' | 'p';

type SupportedTextElementInterfaces =
  | HTMLSpanElement
  | HTMLDivElement
  | HTMLParagraphElement;

const bodyTextSizeMap: Record<SupportedTextSizes, string> = {
  body0: 'text-xl leading-6', // 20px
  body1: 'text-lg leading-6', // 18px
  body2: 'text-base leading-5', // 16px
  body3: 'text-sm leading-[22px]', // 14px
  mini: 'text-xs leading-[18px]', // 12px
  document: '', // left intentionally blank as this size will inherit font size from the document editor
};

interface TextProps extends BaseTextProps<SupportedTextSizes> {
  element?: SupportedTextElementTypes;
  customSizeClassOverride?: string;
  tooltipOn?: boolean;
  'data-testid'?: string;
  trackingId?: TrackingId;
  style?: any;
}

export const BodyText = (props: TextProps) => {
  const overflowingText = useRef<SupportedTextElementInterfaces | null>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const {
    children,
    element = 'p',
    status = 'default',
    weight = 'normal',
    italic = false,
    size = 'body1',
    className = '',
    /* j-weave: Temporary property while we get rid of important for custom size classes */
    customSizeClassOverride,
    color,
    id,
    onClick,
    tooltipOn = true,
    'data-testid': dataTestId,
    trackingId,
    style,
  } = props;

  const isEllipsisActive = (
    ref: SupportedTextElementInterfaces | null,
  ): boolean => {
    if (ref) {
      return (
        ref.offsetHeight < ref.scrollHeight || ref.offsetWidth < ref.scrollWidth
      );
    }

    return false;
  };

  useEffect(() => {
    if (isEllipsisActive(overflowingText?.current) && tooltipOn) {
      setIsOverflowed(true);
      return;
    }

    setIsOverflowed(false);
  }, [isOverflowed]);

  const renderTooltip = isOverflowed ? children : '';

  // The tag repetition - and casting - seen below (as opposed to the creation seen in <Heading>)
  // is due to the ref object attributes being different between span tags (which don't have alignment)
  // vs. div and paragraph tags (which do).

  if (element === 'span') {
    return (
      <Tooltip title={renderTooltip}>
        <span
          className={cn(
            color ? color : textColorMap[status],
            customSizeClassOverride ?
              customSizeClassOverride
            : bodyTextSizeMap[size],
            textFontWeightMap[weight],
            italic ? 'italic' : 'not-italic',
            className,
          )}
          id={id}
          ref={overflowingText}
          onClick={onClick}
          data-testid={dataTestId}
          data-tracking-id={trackingId}
          style={style}
        >
          {children}
        </span>
      </Tooltip>
    );
  }
  if (element === 'div') {
    return (
      <Tooltip title={renderTooltip}>
        <div
          className={cn(
            color ? color : textColorMap[status],
            bodyTextSizeMap[size],
            textFontWeightMap[weight],
            italic ? 'italic' : 'not-italic',
            customSizeClassOverride ?
              customSizeClassOverride
            : bodyTextSizeMap[size],
            className,
          )}
          ref={overflowingText as ForwardedRef<HTMLDivElement>}
          id={id}
          onClick={onClick}
          data-testid={dataTestId}
          style={style}
        >
          {children}
        </div>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={renderTooltip}>
      <p
        className={cn(
          color ? color : textColorMap[status],
          bodyTextSizeMap[size],
          textFontWeightMap[weight],
          italic ? 'italic' : 'not-italic',
          customSizeClassOverride ?
            customSizeClassOverride
          : bodyTextSizeMap[size],
          className,
        )}
        ref={overflowingText as ForwardedRef<HTMLParagraphElement>}
        onClick={onClick}
        id={id}
        data-testid={dataTestId}
        data-tracking-id={trackingId}
        style={style}
      >
        {children}
      </p>
    </Tooltip>
  );
};

export default BodyText;
