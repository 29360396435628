import React, { useState } from 'react';

import { useAuth } from '~/core/contexts/AuthContext';
import { IndManagerProvider } from '~/core/contexts/IndManagerContext/IndManagerProvider';
import { UserProvider } from '~/core/contexts/UserContext/UserProvider';
import { WeaveModal } from '~/design-system/components/organisms/WeaveModal/WeaveModal';
import { PublicRoutes } from '~/router/public-routes.tsx';

interface PrivateRouteWrapperProps {
  children: React.ReactNode;
}

export const PrivateRouteWrapper = ({ children }: PrivateRouteWrapperProps) => {
  const [isSessionModalVisible, setSessionModalVisible] = useState(false);
  const { logout } = useAuth();
  const handleSessionInvalid = () => setSessionModalVisible(true);

  const onFinish = async () => {
    await logout();
    window.location.href = PublicRoutes.SIGN_IN;
  };

  return (
    <UserProvider onSessionInvalid={handleSessionInvalid}>
      <IndManagerProvider>
        {children}
        {isSessionModalVisible && (
          <WeaveModal
            open={true}
            title="Your session has expired."
            size="sm"
            cancelButtonText="Cancel"
            hideCancelButton={true}
            onCancel={() => {}}
            onOk={onFinish}
            okButtonText="Sign in"
            trackingId="SESSION_EXPIRED_MODAL"
          >
            <div>We need you to sign in to AutoIND again.</div>
          </WeaveModal>
        )}
      </IndManagerProvider>
    </UserProvider>
  );
};
