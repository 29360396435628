type Props = {
  children: React.ReactNode;
};

export const MswProvider = (props: Props) => {
  if (import.meta.env.MODE === 'test') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // j-weave: This addition predates the FE team, will look into retiring it
    import('../../mocks/browser').then((res) => {
      res.worker.start();
    });
  }

  return <>{props.children}</>;
};
