// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import listToTree from 'list-to-tree-lite';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import flatten from 'tree-flatten';

interface TreeUtilFlattenTreeOptions {
  childPropName: string;
}

interface TreeUtilCreateFromArrayOptions {
  childPropName: string;
  idPropName: string;
  parentPropname: string;
}

export class TreeUtils {
  static flattenTree({
    sourceTree,
    options = { childPropName: 'children' },
  }: {
    sourceTree: any;
    options?: TreeUtilFlattenTreeOptions;
  }): Array<any> {
    return flatten(sourceTree, options.childPropName);
  }

  static createFromArray({
    sourceArray,
    options,
  }: {
    sourceArray: any;
    options?: TreeUtilCreateFromArrayOptions;
  }): any {
    if (options) {
      const listToTreeOptionsObj = {
        idKey: options.idPropName || 'id',
        parentKey: options.parentPropname || 'parent',
        childrenKey: options.childPropName || 'children',
      };
      return listToTree(sourceArray, listToTreeOptionsObj);
    }
    return listToTree(sourceArray, options);
  }

  static nodeHasDescendantWithCondition<TNodeType>({
    node,
    descendantAccessor,
    matchingCondition,
  }: {
    node: TNodeType;
    descendantAccessor: (node: TNodeType) => TNodeType[];
    matchingCondition: (node: TNodeType) => boolean;
  }): boolean {
    const descendants = descendantAccessor(node);
    for (const descendant of descendants) {
      if (
        matchingCondition(descendant) ||
        this.nodeHasDescendantWithCondition({
          node: descendant,
          descendantAccessor,
          matchingCondition,
        })
      ) {
        return true;
      }
    }
    return false;
  }
}
