import { useFeatureFlagEnabled } from 'posthog-js/react';

export type FEATURE_FLAGS =
  | 'fe-allow-template-management'
  | 'fe-allow-zero-files-on-template-playgrounds'
  | 'fe-better-qc'
  | 'fe-show-templated-table-instructions'
  | 'fe-templates-allow-module-3'
  | 'fe-gap-analysis'
  | 'fe-m3-tables'
  | 'fe-exp-dashboard';

/**
 * Checks if a feature is enabled or not. If the result is undefined, probably the feature is
 * either missing from the posthog env, or loading.
 * @param feature the name of the feature flag
 * @returns a bool specifying if it's enabled or not, otherwise undefined
 */
export const useIsFeatureEnabled = (
  feature: FEATURE_FLAGS,
): boolean | undefined => {
  return useFeatureFlagEnabled(feature);
};
