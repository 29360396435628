import { LogAreaType, LogProvider } from '../..';

const consoleLogColorMap: Map<string, string> = new Map<string, string>([
  ['ArchitectureAndSettings', 'green'],
  ['Component', 'seagreen'],
  ['Http', 'blue'],
  ['Service', 'steelblue'],
]);

export class ConsoleLogProvider implements LogProvider {
  logArea = (
    area: string,
    areaType: LogAreaType,
    areaCallback: (loggedArea: string) => void,
  ) => {
    const color = consoleLogColorMap.get(areaType);
    console.group(
      `%c [${area}]`,
      `color:${color};font-weight:bold;border: solid 1px light${color};padding:2px`,
    );
    areaCallback(area);
    console.groupEnd();
  };

  log = (message: string, context?: Record<string, any> | undefined) => {
    context ? console.log(message, context) : console.log(message);
  };

  logError = (
    error: string | Error,
    context?: Record<string, any> | undefined,
  ) => {
    context ? console.error(error, context) : console.error(error);
  };
}
