import axios from 'axios';

export const awsApi = (url: string) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: +import.meta.env.VITE_API_TIMEOUT,
  });
};
