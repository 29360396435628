import { useState } from 'react';
import { useAsync } from 'react-use';

import { useStrictParams } from '~/core/hooks/core/useStrictParams';
import logger from '~/core/providers/logger';
import { getQcInfo } from '~/features/better-qc/services/qc-service';
import { QcData } from '~/features/better-qc/types/qc-types';

import { QcContext } from '.';

interface QcProviderProps {
  children: React.ReactNode;
}

const QcProvider = ({ children }: QcProviderProps) => {
  const { indId, sectionId } = useStrictParams<{
    indId: string;
    sectionId: string;
  }>();

  const [qcInfo, setQcInfo] = useState<QcData>({
    id: '',
    reviewers: [],
    flags: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshQcInfo = async () => {
    if (!indId || !sectionId) {
      console.debug('No indId or sectionId provided, skipping qc info refresh');
      return;
    }

    setIsLoading(true);
    try {
      const qcInfoResponse = await getQcInfo({
        indId,
        documentNumber: sectionId,
      });
      if (qcInfoResponse.error) {
        throw new Error(qcInfoResponse.error.message);
      }
      if (!qcInfoResponse.data) {
        throw new Error('No data returned from qc info endpoint');
      }
      const qcInfoData = qcInfoResponse.data;
      setQcInfo(qcInfoData);
      return qcInfoData;
    } catch (e) {
      logger.logError('Failed to fetch qc info', { indId, sectionId });
    } finally {
      setIsLoading(false);
    }
    return undefined;
  };

  useAsync(() => refreshQcInfo(), []);

  return (
    <QcContext.Provider
      value={{
        qcInfo,
        setQcInfo,
        refreshQcInfo,
        isLoading,
      }}
    >
      {children}
    </QcContext.Provider>
  );
};

export { QcProvider };
