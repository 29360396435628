const mapProfileResponseDataToFriendlyName = (
  profileData:
    | { firstName: string; lastName: string; email: string }
    | { first_name: string; last_name: string; email: string },
): string => {
  if ('firstName' in profileData && 'lastName' in profileData) {
    return profileData.firstName && profileData.lastName ?
        `${profileData.firstName} ${profileData.lastName}`
      : profileData.email;
  } else {
    // assume that the latter type case is the one utilized
    return profileData.first_name && profileData.last_name ?
        `${profileData.first_name} ${profileData.last_name}`
      : profileData.email;
  }
};

export const FriendlyNameMapper = {
  map: {
    profileResponseData: {
      to: {
        friendlyName: mapProfileResponseDataToFriendlyName,
      },
    },
  },
};
