import './index.scss';

import { Button, Typography } from '@weavebio/ui-toolkit';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';

import {
  BACK_TO_HOME_PAGE,
  FOUR_OH_FOUR_DESCRIPTION,
  FOUR_OH_FOUR_TITLE,
} from '~/pages/common/Page404/const';
import { PrivateRoutes } from '~/router/private-routes';

import Animation404 from './404-flat.json';

const Page404 = () => {
  const navigate = useNavigate();

  const onHomeButtonClicked = () => {
    navigate(PrivateRoutes.ROOT);
  };

  return (
    <div data-testid="page-404" className="page-404">
      <Lottie loop play animationData={Animation404} style={{ height: 180 }} />
      <Typography as="h3" variant="h3">
        {FOUR_OH_FOUR_TITLE}
      </Typography>
      <Typography as="h4" variant="body-1-regular">
        {FOUR_OH_FOUR_DESCRIPTION}
      </Typography>
      <Button className="homepage-button" onClick={onHomeButtonClicked}>
        {BACK_TO_HOME_PAGE}
      </Button>
    </div>
  );
};

export default Page404;
