import { useEffect } from 'react';

export const useResizeObserver = <T,>(
  ref: React.MutableRefObject<T>,
  onResize: (el: T) => void,
  rerun: any[] = [],
) => {
  useEffect(() => {
    const element = ref?.current;

    const handleResize = () => {
      if (element) {
        onResize(element);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, ...rerun]);
};
