import 'console.img';

// Disabling - this is one of three areas where we directly reference Sentry: two abstractions and one initialization
// a) --> [this one] the Sentry.init
// b) SentryLogProvider
// c) SentryUserReporter
// eslint-disable-next-line local/no-sentry-import
import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import App from '~/App';

import { logSettings } from './logSettings';

logSettings();

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_ENVIRONMENT = import.meta.env.MODE;
const ENABLE_SENTRY = !!import.meta.env.VITE_ENABLE_SENTRY;

if (ENABLE_SENTRY === true) {
  try {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/dev-api\.weave\.bio\/api/,
        /^https:\/\/prod-api\.weave\.bio\/api/,
        /^https:\/\/weave-stg\.apps\.jnj\/api/,
      ],
      // Performance Monitoring
      // i.e. traceSampleRate: SENTRY_ENVIRONMENT === 'development' ? 0.1 : 1.0,
      tracesSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (e) {
    console.log('Error initializing Sentry:', e);
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
