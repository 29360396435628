import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ReferencesStore {
  enableReferencesEmptyState: boolean;
  isPendingReferencesRefreshAfterEdit: boolean;
  isReloadingReferences: boolean;
  setEnableReferencesEmptyState: (refState: boolean) => void;
  setIsPendingReferencesRefreshAfterEdit: (isPending: boolean) => void;
  setIsReloadingReferences: (isReloading: boolean) => void;
}

export const useReferencesStore = create<ReferencesStore>()(
  devtools(
    (set) => ({
      enableReferencesEmptyState: false,
      isPendingReferencesRefreshAfterEdit: false,
      isReloadingReferences: false,
      setEnableReferencesEmptyState: (refState) =>
        set({ enableReferencesEmptyState: refState }),
      setIsPendingReferencesRefreshAfterEdit: (isPending) =>
        set({ isPendingReferencesRefreshAfterEdit: isPending }),
      setIsReloadingReferences: (isReloading) =>
        set({ isReloadingReferences: isReloading }),
    }),
    {
      name: 'references-store',
    },
  ),
);
