import { ArrowRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { WeaveButton } from '~/design-system/components/atoms/WeaveButton';

interface TimelineSectionProps {
  title: string;
  description: string;
  buttonText: string;
  linkTo: string;
  linkState?: any;
  disabled?: boolean;
}

export const TimelineSection = ({
  title,
  description,
  buttonText,
  linkTo,
  linkState,
  disabled,
}: TimelineSectionProps) => (
  <div className="flex flex-col pl-2 gap-1">
    <div className="flex flex-row items-center justify-between -mt-1">
      <div className="text-weave-primary-200 text-[14px]">{title}</div>
      <div className="max-w-fit">
        <Link
          to={linkTo}
          state={linkState}
          className={disabled ? 'pointer-events-none' : ''}
        >
          <WeaveButton
            className="pt-1 px-2 text-gray-800 text-[14px] hover:bg-gray-100 disabled:text-gray-400 disabled:bg-transparent"
            icon={<ArrowRightOutlined />}
            disabled={disabled}
            type="link"
          >
            {buttonText}
          </WeaveButton>
        </Link>
      </div>
    </div>
    <div className="text-weave-primary-600 text-[12px]">{description}</div>
  </div>
);
