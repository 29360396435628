import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { AuthenticatedUser } from '~/core/domain/types';
import logger from '~/core/providers/logger';

const authApiAdapter = new IndHttpAdapter();

const authEndpoints = {
  signIn: 'account/login',
  signOut: 'account/logout',
  initiatePasswordReset: 'account/password-reset-request',
  confirmResetPassword: 'account/password-reset-confirm',
  changePassword: 'account/password-change',
};

interface SignInInputModel {
  email: string;
  password: string;
}

interface PasswordResetConfirmInput {
  token: string;
  email: string;
  new_password: string;
}

interface PasswordChangeInput {
  current_password: string;
  new_password: string;
}

const login = async (
  data: SignInInputModel,
): Promise<AuthenticatedUser | null> => {
  const response = await authApiAdapter.post<AuthenticatedUser>({
    endpoint: authEndpoints.signIn,
    data,
  });

  if (response.error || !response.data) {
    return null;
  }

  return response.data;
};

const logout = async (): Promise<boolean> => {
  const response = await authApiAdapter.post({
    endpoint: authEndpoints.signOut,
    data: {},
  });
  if (response.error) {
    logger.logError('Logout failed', { error: response.error });
    return false;
  }

  localStorage.removeItem('sessionToken');
  return true;
};
const initiatePasswordReset = async (email: string): Promise<boolean> => {
  const response = await authApiAdapter.post({
    endpoint: authEndpoints.initiatePasswordReset,
    data: { email },
  });

  if (response.error || !response.data) {
    logger.logError('Failed to initiate password reset', {
      error: response.error,
    });
    return false;
  }

  return true;
};

const confirmResetPassword = async (
  data: PasswordResetConfirmInput,
): Promise<boolean> => {
  const response = await authApiAdapter.post({
    endpoint: authEndpoints.confirmResetPassword,
    data,
  });

  if (response.error || !response.data) {
    logger.logError('Password reset confirmation failed', {
      error: response.error,
    });
    return false;
  }

  return true;
};

const changePassword = async (data: PasswordChangeInput): Promise<boolean> => {
  const response = await authApiAdapter.post({
    endpoint: authEndpoints.changePassword,
    data,
  });

  if (response.error || !response.data) {
    logger.logError('Password change failed', { error: response.error });
    return false;
  }

  return true;
};

export {
  changePassword,
  confirmResetPassword,
  initiatePasswordReset,
  login,
  logout,
};
