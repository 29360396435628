import './index.scss';

import { Button, Typography } from '@weavebio/ui-toolkit';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';

import { PrivateRoutes } from '~/router/private-routes';

import animation505 from './500-flat.json';

type Props = {
  resetErrorBoundary?: () => void;
};

const Page500 = (props: Props) => {
  const navigate = useNavigate();

  const buttonClicked = () => {
    props?.resetErrorBoundary?.();
    navigate(PrivateRoutes.ROOT);
  };

  return (
    <div className="page-500">
      <Lottie loop play animationData={animation505} style={{ height: 180 }} />
      <Typography as="h3" variant="h3">
        {'Error'}
      </Typography>
      <Typography as="h4" variant="body-1-regular">
        {'Something went wrong'}
      </Typography>
      <Button className="homepage-button" onClick={buttonClicked}>
        Back to Home Page
      </Button>
    </div>
  );
};

export default Page500;
