import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import logger from '../logger';
interface PosthogConfigProps {
  children: React.ReactNode;
}

const POSTHOG_API_HOST = 'https://app.posthog.com' as const;

export const PosthogConfig = ({ children }: PosthogConfigProps) => {
  const apiKey: string | undefined = import.meta.env.VITE_POSTHOG_API_KEY;

  if (apiKey) {
    posthog.init(apiKey, {
      api_host: POSTHOG_API_HOST,
      disable_session_recording: false,
    });
  } else {
    logger.logError(
      'No posthog configuration was found - unexpected behavior may result.  Please check the env settings of the environment in which you are running.',
    );
  }

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
