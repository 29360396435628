import { CloseOutlined } from '@ant-design/icons';
import { ConfigProvider, Timeline } from '@weavebio/ui-toolkit';
import { useContext } from 'react';

import UserContext from '~/core/contexts/UserContext';
import { useStrictParams } from '~/core/hooks/core/useStrictParams';
import * as UserService from '~/core/services/UserService';
import { PrivateRoutes } from '~/router/private-routes';

import { TimelineDot } from './TimelineDot';
import { TimelineSection } from './TimelineSection';

interface QuickStartProps {
  onClose: () => void;
}

export const QuickStart = (props: QuickStartProps) => {
  const { userProfileSettings, setUserProfileSettings } =
    useContext(UserContext);
  const params = useStrictParams<{
    indId: string;
  }>();

  const timelineItems = [
    {
      dot: <TimelineDot n={1} />,
      children: (
        <TimelineSection
          title="Upload source documents"
          description="Go to the Data Room and upload any available source documents you have. We recommend starting with a non-clinical study report as input for 2.6."
          buttonText="Go to Data Room"
          linkTo={`${PrivateRoutes.DATA_ROOM.replace(':indId', params.indId)}`}
          linkState={{ showUploadModal: true }}
        />
      ),
    },
    {
      dot: <TimelineDot n={2} />,
      children: (
        <TimelineSection
          title="Generate IND sections"
          description="Select the Generate button on your section of interest to kick off your content. If you uploaded a non-clinical report, you can generate a written summary in 2.6 next."
          buttonText="Search for a section"
          linkTo={`${PrivateRoutes.IND_MANAGER.replace(
            ':indId',
            params.indId,
          )}`}
          linkState={{ shouldSetSearchFromGuide: true }}
        />
      ),
    },
    {
      dot: <TimelineDot n={3} />,
      children: (
        <TimelineSection
          title="Discover what's next"
          description="Some IND sections are inputs for other sections. Use the status filter to see what sections have been unlocked. If you previously generated part of 2.6, you can navigate to 2.4 to see what’s next."
          buttonText="Filter for unlocked sections"
          linkTo={`${PrivateRoutes.IND_MANAGER.replace(
            ':indId',
            params.indId,
          )}`}
          linkState={{ shouldSetStatusFilterFromGuide: true }}
        />
      ),
    },
  ];

  const onCancel = async () => {
    props.onClose();
    // If this is the user's first time seeing the guide, update their profile settings to mark it as seen
    // so it doesn't automatically pop up in the future
    if (!userProfileSettings?.hasSeenContentGenerationGuide) {
      await UserService.updateProfileSettings({
        ...userProfileSettings,
        hasSeenContentGenerationGuide: true,
      });
      setUserProfileSettings({
        ...userProfileSettings,
        hasSeenContentGenerationGuide: true,
      });
    }
  };

  return (
    <div className="w-full px-8 py-4 flex flex-col justify-start items-start gap-4 h-[354px] max-h-[354px] max-w-[512px]">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="text-xs font-semibold leading-7 tracking-2px uppercase text-weave-coral">
          Content Generation Guide
        </div>
        <CloseOutlined onClick={onCancel} />
      </div>
      <ConfigProvider
        theme={{
          components: {
            Timeline: {
              tailColor: 'rgb(107 114 128)',
              tailWidth: 1,
            },
          },
        }}
      >
        <Timeline items={timelineItems} />
      </ConfigProvider>
    </div>
  );
};
